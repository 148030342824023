import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useAuthenticate, useSetConfig } from '../hooks/useAuth';
import { constants } from '../constants';
import { setToken } from '../services/api';
import { useLanguageContext } from '../context/LanguageContext';
import { useStores } from '../context/StoreContext';
import Header from './Header';
import PdfList from './PdfList';
import Footer from './Footer';
import SelectModal from './Modal/SelectModal';
import Home from './HtmlManuals/pages/Home';
import Topic from './HtmlManuals/pages/Topic';
import Browse from './HtmlManuals/pages/Browse';
import BrowseSecondary from './HtmlManuals/pages/BrowseSecondary';
import Downloads from './HtmlManuals/pages/Downloads';
import NotFound from './HtmlManuals/pages/NotFound';
import styled, { ThemeProvider } from 'styled-components';
import Theme, { theme } from '../Theme';
import { useMatomo } from 'src/hooks/useMatomo';

const App = observer(() => {
  useMatomo();
  const { mutateAsync } = useAuthenticate();
  const configMutation = useSetConfig();
  const [loaded, setLoaded] = useState(false);
  const [showRegionModal, setShowRegionModal] = useState(false);
  const { region } = useLanguageContext();
  const { authStore, pdfStore, searchStore } = useStores();

  useEffect(() => {
    if (!loaded) return;
    const hasVisited = localStorage.getItem('hasVisitedBefore') === 'true';
    const isInitialLoad =
      !hasVisited && !localStorage.getItem('selectedRegion');
    if (isInitialLoad) {
      setShowRegionModal(true);
    } else if (region) {
      pdfStore.setSelectedMarket(region);
    }
  }, [loaded, region, pdfStore, searchStore]);

  useEffect(() => {
    authStore.initFromStorage();
  }, []);

  useEffect(() => {
    const setup = async () => {
      const authenticated = await mutateAsync();
      if (authenticated.status === 200) {
        setToken(constants.tokenVersion, authenticated.data.token);
      }
      const config = await configMutation.mutateAsync();
      if (config.status === 200 && config.data) {
        setToken(constants.configVersion, JSON.stringify(config.data));
        setLoaded(true);
      }
    };
    setup();
  }, []);

  if (!loaded) {
    return null;
  }

  return (
    <Router
      basename={
        process.env.REACT_APP_BASE_URL
          ? new URL(process.env.REACT_APP_BASE_URL).pathname
          : ''
      }
    >
      <Switch>
        {/* HTML Manuals Routes */}
        <Route path="/html">
          <ThemeProvider theme={theme}>
            <Switch>
              <Route exact path={['/html', '/html/404']} component={NotFound} />
              <Route
                exact
                path="/html/:manifestId/parts/:childId"
                component={BrowseSecondary}
              />
              <Route
                exact
                path="/html/:manifestId/parts"
                component={BrowseSecondary}
              />
              <Route
                exact
                path="/html/:manifestId/downloads"
                component={Downloads}
              />
              <Route
                exact
                path="/html/:manifestId/topics/:topicId"
                component={Topic}
              />
              <Route
                exact
                path="/html/:manifestId/:path/topics/:topicId"
                component={Topic}
              />
              <Route exact path="/html/:manifestId" component={Home} />
              <Route
                exact
                path="/html/:manifestId/:path/:childId"
                component={Browse}
              />
              <Route exact path="/html/:manifestId/:path" component={Browse} />
            </Switch>
          </ThemeProvider>
        </Route>

        {/* PDF Finder Route */}
        <Route path="/" exact>
          <Wrapper>
            <PageRow>
              <Header />
            </PageRow>
            <PageRow expanded>
              <PdfList />
            </PageRow>
            <PageRow>
              <Footer />
            </PageRow>
            <SelectModal
              isVisible={showRegionModal}
              onClose={() => setShowRegionModal(false)}
            />
          </Wrapper>
        </Route>
      </Switch>
    </Router>
  );
});

const Wrapper = styled.div`
  min-height: 100%;
  width: 100%;
  display: table;
`;

const PageRow = styled.div<{ expanded?: boolean }>`
  display: table-row;
  height: ${(props) => (props.expanded ? '100%' : 'auto')};
`;

export default App;
