import styled from "styled-components";

export const ModalContent = styled.div`
  margin-top: 20px;
`;

export const ModalRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const RegionItemWrapper = styled.div<{
  isHovered: boolean;
  isSelected: boolean;
}>`
  cursor: pointer;
`;

export const IconWrapper = styled.div<{ isHovered: boolean; isSelected: boolean }>`
  width: 185px;
  flex-direction: row;
  margin: 6px;
  padding: 1px;
  border-radius: 6px;
  background-color: ${({ isHovered, theme }) =>
    isHovered ? theme.colors.esabLightGray : theme.colors.esabWhite};
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
  border: ${({ isSelected }) =>
    isSelected ? '1px solid #484549' : '1px solid transparent'};
  transition: all 0.2s ease;
`;

export const RegionName = styled.p<{ isSelected: boolean }>`
  text-align: center;
  font-size: 16px;
  font-weight: ${({ isSelected }) => (isSelected ? 'bold' : 'normal')};
`;