import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../../hooks/useTranslation';
import { useInternalLink } from '../../../hooks/useInternalLink';
import { useTheme } from 'styled-components';
import { ThemeType } from 'src/Theme';
const Item = styled.li`
  border-bottom: 0.63px solid ${({ theme }) => theme.colors.esabBlack44};

  &:focus {
    outline: none;
  }
`;

const StyledLinkItemChild = styled.div`
  color: ${({ theme }) => theme.colors.esabBlack80};
`;

const StyledLink = styled.a<{ focus: boolean; inHeader: boolean | undefined }>`
  display: block;
  padding: 16.92px 25.69px 12.08px 25.69px;
  cursor: pointer;
  font-size: ${({ theme, inHeader }) =>
    inHeader ? theme.fontSizes.s : '18px'};
  line-height: ${({ inHeader }) => (inHeader ? '16.1px' : '20.7px')};
  background: ${({ focus }) => (focus ? 'rgba(0,0,0, .1)' : 'transparent')};

  &:focus {
    outline: none;
  }
`;

const StyledLinkItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledType = styled.div`
  color: ${({ theme }) => theme.colors.esabBlack80};
  display: flex;
  align-items: center;
  border-radius: 4px;
  border-width: 1.5px;
  text-align: center;
  width: auto;
  height: 2.5em;
  font-size: ${({ theme }) => theme.fontSizes.xxxs};
  font-weight: 700;
  line-height: 11.5px;
  padding: 0px 10px 0px 10px;
`;

type LinkItemProps = {
  title: string;
  type?: Esab.MapFileType;
};

const LinkItem: React.FC<LinkItemProps> = ({ title, type }) => {
  const { t } = useTranslation();
  const theme = useTheme() as ThemeType;

  const getBorderColor = (type: string): string => {
    let color = theme.colors.esabBlack;

    switch (type) {
      case 'service':
        color = theme.colors.infoTypes.service;
        break;
      case 'instruction':
        color = theme.colors.infoTypes.instruction;
        break;
      case 'parts':
        color = theme.colors.infoTypes.parts;
        break;
    }

    return color;
  };

  return (
    <StyledLinkItem>
      <StyledLinkItemChild>{`${title.charAt(0).toUpperCase()}${title
        .slice(1)
        .toLowerCase()}`}</StyledLinkItemChild>
      {type ? (
        <StyledType
          style={{
            borderColor: getBorderColor(type.toLowerCase()),
          }}
        >
          <span>{t(`search.mapfile.${type}`)}</span>
        </StyledType>
      ) : null}
    </StyledLinkItem>
  );
};

type SearchBoxResultsItemProps = {
  data: Esab.SearchResultDocument;
  focus: boolean;
  index: number;
  setFocus: Dispatch<SetStateAction<number>>;
  inHeader?: boolean;
};

const SearchResultItem: React.FC<SearchBoxResultsItemProps> = ({
  data,
  focus,
  index,
  setFocus,
  inHeader,
}) => {
  const ref = React.useRef<HTMLAnchorElement>(null);
  const { internalLink } = useInternalLink();
  React.useEffect(() => {
    if (focus && ref.current !== null) {
      ref.current.focus();
    }
  }, [focus, ref]);

  const handleSelect = () => {
    setFocus(index);
  };

  const generateLink = () => {
    return internalLink(
      !data.informationType
        ? `/${data.mapfile?.toLowerCase()}/${data.topicId}`
        : `/${data.mapfile?.toLowerCase()}/topics/${data.topicId}`
    );
  };

  return (
    <Item tabIndex={focus ? 0 : -1} role="button" onMouseOver={handleSelect}>
      <StyledLink
        focus={focus}
        ref={ref}
        href={generateLink()}
        inHeader={inHeader}
      >
        <LinkItem title={data.title} type={data.mapfile} />
      </StyledLink>
    </Item>
  );
};

export default SearchResultItem;
