import * as React from 'react';
import Modal from './Modal';
import styled from 'styled-components';
import TopicContent from '../TopicContent';
import { useFetchSettings } from '../../../hooks/useFetchData';

const Wrapper = styled.div`
  padding: 0 16px 16px;
`;

type QuestionmarkModalProps = {
  isVisible: boolean;
  closeHandler: () => void;
};

const QuestionmarkModal: React.FC<QuestionmarkModalProps> = ({
  isVisible,
  closeHandler,
}) => {
  const { isLoading, data } = useFetchSettings();
  let questionmarkTopicId = null;
  if (!isLoading) {
    questionmarkTopicId = data.QuestionMarkId;
  }

  return (
    <Modal isVisible={isVisible} onClose={closeHandler}>
      <Wrapper>
        <TopicContent topicId={questionmarkTopicId} />
      </Wrapper>
    </Modal>
  );
};

export default QuestionmarkModal;
