import React from 'react';
import styled from 'styled-components';
import Modal from './Modal';
import logo from '../../assets/images/logo.png';

interface LoginModalProps {
  isVisible: boolean;
  onClose: () => void;
  onLogin: () => void;
  onCreateAccount: () => void;
}

const LoginModal: React.FC<LoginModalProps> = ({
  isVisible,
  onClose,
  onLogin,
  onCreateAccount,
}) => {
  const handleLogin = () => {
    sessionStorage.setItem('originalUrl', window.location.href);
    sessionStorage.setItem('scrollPosition', window.scrollY.toString());
    window.location.href = process.env.REACT_APP_ESAB_LOGIN_URL;
  };

  return (
    <Modal isVisible={isVisible} onClose={onClose} title="Log in">
      <ModalContent>
        <LogoContainer>
          <Logo src={logo} alt="Logo" />
        </LogoContainer>
        <MessageText>
          You need to be logged in to access Service Manuals. Log in to the
          Partner Login using the button below or create an account.
        </MessageText>
        <ButtonContainer>
          <CreateAccountButton onClick={onCreateAccount}>
            Create Account
          </CreateAccountButton>
          <LoginButton onClick={handleLogin}>Login</LoginButton>
        </ButtonContainer>
      </ModalContent>
    </Modal>
  );
};

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 475px;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0 40px;
`;

const Logo = styled.img`
  max-width: 120px;
  height: auto;
`;

const MessageText = styled.p`
  text-align: center;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.esabBlack};
  padding-bottom: 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.esabLightGray};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-top: 40px;
  margin-bottom: 15px;
`;

const Button = styled.button`
  flex: 1;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
`;

const CreateAccountButton = styled(Button)`
  background: ${({ theme }) => theme.colors.esabWhite};
  color: ${({ theme }) => theme.colors.esabBlack};

  &:hover {
    background-color: ${({ theme }) => theme.colors.esabLightGray};
  }
`;

const LoginButton = styled(Button)`
  background: ${({ theme }) => theme.colors.esabYellow};
  color: ${({ theme }) => theme.colors.esabBlack};

  &:hover {
    background-color: ${({ theme }) => theme.colors.esabLightGray};
  }
`;

export default LoginModal;
