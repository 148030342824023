import React from 'react';
import styled from 'styled-components';

const ResetWrapper = styled.div`
  && {
    background: #ffffff;
  }
  & {
    /* Box sizing */
    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }

    /* Root styles */
    :root {
      -moz-tab-size: 4;
      tab-size: 4;
    }

    /* Base elements */
    html {
      line-height: 1.15;
      -webkit-text-size-adjust: 100%;
      font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
        'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
        'Noto Color Emoji';
      line-height: 1.5;
    }

    body,
    #root,
    #root > div {
      background: inherit;
    }

    /* Block elements */
    p,
    blockquote,
    dl,
    dd,
    hr,
    figure,
    pre {
      margin: 0;
    }

    /* Lists */
    ol,
    ul {
      list-style: none;
    }

    /* Form elements */
    button,
    input,
    optgroup,
    select,
    textarea {
      font-family: inherit;
      font-size: 100%;
      line-height: inherit;
    }

    button {
      background-color: transparent;
      background-image: none;
      cursor: pointer;
    }

    /* Tables */
    table {
      text-indent: 0;
      border-color: inherit;
      border-collapse: collapse;
    }

    /* Links */
    a {
      font-family: Arial, sans-serif;
      color: inherit;
      text-decoration: inherit;
    }

    /* Media elements */
    img,
    svg,
    video,
    canvas,
    audio,
    iframe,
    embed,
    object {
      display: block;
      vertical-align: middle;
    }

    img,
    video {
      max-width: 100%;
      height: auto;
    }

    /* Code elements */
    pre,
    code,
    kbd,
    samp {
      font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
        'Liberation Mono', 'Courier New', monospace;
    }

    /* Border reset */
    *,
    ::before,
    ::after {
      border-width: 0;
      border-style: solid;
      border-color: #e5e7eb;
    }

    /* Misc elements */
    hr {
      height: 0;
      color: inherit;
      border-top-width: 1px;
    }

    /* Form specific */
    textarea {
      resize: vertical;
    }

    input::placeholder,
    textarea::placeholder {
      color: #9ca3af;
    }

    ::-webkit-file-upload-button {
      -webkit-appearance: button;
      font: inherit;
    }
  }
`;

const HtmlManualWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return <ResetWrapper>{children}</ResetWrapper>;
};

export default HtmlManualWrapper;
