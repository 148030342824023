import React, { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as WarningIcon } from '../icons/warning.svg';
import { ReactComponent as CautionIcon } from '../icons/caution.svg';
import { ReactComponent as NoteIcon } from '../icons/info.svg';
import { ReactComponent as DangerIcon } from '../icons/danger.svg';

const getType = (classes: string) => {
  let type: Esab.AttentionTypes;
  const attentionTypes: Esab.AttentionTypes[] = [
    'warning',
    'caution',
    'note',
    'danger',
  ];
  for (const key in attentionTypes) {
    if (classes.includes(attentionTypes[key])) {
      type = attentionTypes[key];
    }
  }
  return type;
};

const getAttentionIcon = (type: string) => {
  if (type === 'warning') {
    return <WarningIcon />;
  } else if (type === 'caution') {
    return <CautionIcon />;
  } else if (type === 'note') {
    return <NoteIcon />;
  } else if (type === 'danger') {
    return <DangerIcon />;
  }
};

const Wrapper = styled.div<{
  warning?: boolean;
  caution?: boolean;
  note?: boolean;
  danger?: boolean;
}>`
  display: flex;
  margin-bottom: ${({ theme }) => theme.space.m};
  border-left-width: ${({ theme }) => theme.borderWidths.attention}px;
  border-left-color: #fecb3c;
  border-left-style: solid;
  max-width: 80%;
  &:last-child {
    margin-bottom: 0;
  }
`;

const AttentionIconWrapper = styled.div`
  margin: 0 ${({ theme }) => theme.space.attention};
`;

const TextWrapper = styled.div``;

const AttentionLabel = styled.span`
  font-family: ${({ theme }) => theme.fonts.regular};
  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: ${({ theme }) => theme.lineHeights.s};
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`;

type AttentionProps = {
  className: string;
  children: React.ReactNode;
};

const Attention: FC<AttentionProps> = ({ className, children }) => {
  const type = getType(className);
  return (
    <Wrapper
      warning={type === 'warning'}
      caution={type === 'caution'}
      note={type === 'note'}
      danger={type === 'danger'}
      className={className}
    >
      <AttentionIconWrapper>{getAttentionIcon(type)}</AttentionIconWrapper>
      <TextWrapper>
        <AttentionLabel>{type}!</AttentionLabel>
        {children}
      </TextWrapper>
    </Wrapper>
  );
};

export default Attention;
