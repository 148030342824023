import styled from 'styled-components';

export const MobileSearchOverlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 75px;
  left: 0;
  width: 100%;
  background: ${({ theme: { colors } }) => colors.esabWhite};
  transform: translateY(${({ isOpen }) => (isOpen ? '0' : '-100%')});
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: 101;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding-top: 10px;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
`;

export const MobileMenu = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 75px;
  left: 0;
  width: 100%;
  height: auto;
  background: ${({ theme: { colors } }) => colors.esabWhite};
  transform: translateY(${({ isOpen }) => (isOpen ? '0' : '-100%')});
  transition: transform 0.3s ease-in-out;
  z-index: 101;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  overflow: hidden;
`;

export const MobileMenuItem = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border: none;
  background: none;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.esabLightGray};
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;

  &:last-child {
    border-bottom: none;
  }
`;

export const MobileLeftSection = styled.div`
  display: flex;
  align-items: center;
`;

export const MobileCenterSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MobileRightSection = styled.div`
  display: flex;
  align-items: center;
`;

export const MobileLogo = styled.img`
  height: 24px;
  width: auto;
`;

export const DropdownArrowWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 5px;
  pointer-events: none;
`;

export const HeaderContainer = styled.div`
  position: relative;
  z-index: 102;
  width: 100%;
  background: #fee509;
  padding: 20px 0 20px 0;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    position: fixed;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    margin: 0 20px;
    justify-content: space-between;
  }
`;

export const LeftSection = styled.div`
  display: flex;
  align-items: center;
`;

export const RightSection = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoLink = styled.a`
  display: inline-block;
`;

export const Logo = styled.img`
  margin-right: 40px;
`;

export const FormWrapper = styled.div<{ logo: boolean }>`
  flex-grow: 1;
  margin-right: 20px;
`;

export const IconButton = styled.button`
  background: none;
  border: none;
  padding: 5px 10px;
  margin-left: 20px;
  color: ${({ theme: { colors } }) => colors.esabBlackGray};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    color: ${({ theme: { colors } }) => colors.esabGrayHover};
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;

export const ButtonText = styled.span`
  font-size: 16px;
  text-transform: uppercase;

  &:hover {
    color: ${({ theme: { colors } }) => colors.esabGrayHover};
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const DisabledButton = styled(IconButton)`
  opacity: 0.6;
  cursor: not-allowed;
`;

export const DisabledMenuItem = styled(MobileMenuItem)`
  opacity: 0.6;
  cursor: not-allowed;
`;

export const SearchOverlayBackground = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 100;
`;
