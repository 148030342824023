import React from 'react';
import { ModalWindow, ModalHeader, CloseIcon, ModalTitle, Overlay } from '../styled/Modal';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  title: string;
}

const Modal: React.FC<Props> = ({ children, isVisible, onClose, title }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Overlay onClick={onClose}>
      <ModalWindow onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          <CloseIcon src="/images/close.svg" onClick={onClose} />
        </ModalHeader>
        {children}
      </ModalWindow> 
    </Overlay>
  );
};

export default Modal;
