import React, { ChangeEvent } from 'react';
import { Label, TextInput } from '../styled/Input';
import { useStores } from 'src/context/StoreContext';
import { InputWrapper, StyledSearchIcon, ClearTextButton, StyledClearTextIcon } from '../styled/InputText';

type Props = {
  title: string;
  name: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  onEnterPress?: () => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

const InputText = ({
  title,
  name,
  value,
  onChange,
  onClick,
  onEnterPress,
  onKeyDown,
}: Props) => {
  const { searchStore } = useStores();

  const handleClearText = () => {
    onChange({ target: { value: '' } } as ChangeEvent<HTMLInputElement>);
    searchStore.performSearch();
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      searchStore.performSearch();
      if (onEnterPress) {
        onEnterPress();
      }
    }
  };

  return (
    <Label>
      <InputWrapper>
        <StyledSearchIcon />
        <TextInput
          placeholder="Search for a product name or document number"
          type="text"
          name={name}
          value={value}
          onChange={onChange}
          onKeyPress={handleKeyPress}
          onClick={onClick}
          autoComplete="off"
          maxLength={50}
          onKeyDown={onKeyDown}
        />
        {value && (
          <ClearTextButton onClick={handleClearText}>
            <StyledClearTextIcon />
          </ClearTextButton>
        )}
      </InputWrapper>
    </Label>
  );
};

export default InputText;
