import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { configure } from 'mobx';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import './index.css';
import { SearchStore, UIStore, PdfStore, AuthStore } from './stores';
import { StoreProvider } from './context/StoreContext';
import { QueryClientProvider, QueryClient } from 'react-query';
import Theme, { theme } from './Theme';
import { RegionLanguageProvider } from './context/LanguageContext';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from 'styled-components';

configure({ useProxies: 'ifavailable' });

const uiStore = new UIStore();
const pdfStore = new PdfStore();
const searchStore = new SearchStore(pdfStore);
const queryClient = new QueryClient();
const authStore = new AuthStore();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <StoreProvider value={{ uiStore, pdfStore, searchStore, authStore }}>
      <ThemeProvider theme={theme}>
        <RegionLanguageProvider>
          <App />
        </RegionLanguageProvider>
      </ThemeProvider>
    </StoreProvider>
  </QueryClientProvider>,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
