export interface ManualVariant {
  lang: string;
  url: string;
  manualtype: string[];
  releasedate?: string;
}

export const BASE_PATH = '/html';

export const formatManualUrl = (variant: ManualVariant) => {
  if (isHtmlManual(variant.manualtype)) {
    const urlParts = variant.url.split('/');
    const manualId = urlParts[urlParts.length - 1];
    return `${BASE_PATH}/${manualId}`;
  }
  return variant.url;
};

export const isHtmlManual = (manualtype: string[]): boolean => {
  return manualtype.includes('HTML');
};
