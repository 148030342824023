export interface AuthResponse {
  accessToken: {
    sv: string;
    se: string;
    sr: string;
    sp: string;
    sig: string;
  };
  expiresOn: string;
}

export interface AuthParams {
  time: string;
  userid: string;
  signature: string;
}

export const authService = {
  async authenticate(params: AuthParams): Promise<AuthResponse> {
    try {
      const url = `${
        process.env.REACT_APP_LOGIN_API
      }/accesstoken?time=${encodeURIComponent(
        params.time
      )}&userid=${encodeURIComponent(
        params.userid
      )}&signature=${encodeURIComponent(params.signature.replace(/ /g, '+'))}`;

      const response = await fetch(url, {
        method: 'GET',
      });
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Auth API Error Response:', errorText);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Authentication Error:', error);
      throw error;
    }
  },

  getAuthParamsFromUrl(): AuthParams | null {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const time = urlParams.get('time');
      const userid = urlParams.get('userid');
      const signature = urlParams.get('signature');

      if (!time || !userid || !signature) {
        return null;
      }

      return { time, userid, signature };
    } catch (error) {
      console.error('Error parsing URL parameters:', error);
      return null;
    }
  },

  appendTokenToUrl(
    url: string,
    accessToken: AuthResponse['accessToken']
  ): string {
    if (!url || !accessToken) return url;

    try {
      const tokenParams = new URLSearchParams();
      Object.entries(accessToken).forEach(([key, value]) => {
        tokenParams.append(key, value);
      });

      const hasParams = url.includes('?');
      return `${url}${hasParams ? '&' : '?'}${tokenParams.toString()}`;
    } catch (error) {
      console.error('Error setting token to URL:', error);
      return url;
    }
  },

  saveToken(token: AuthResponse) {
    try {
      sessionStorage.setItem('authData', JSON.stringify(token));
    } catch (error) {
      console.error('Error saving token:', error);
    }
  },

  getToken(): AuthResponse | null {
    try {
      const token = sessionStorage.getItem('authData');

      return token ? JSON.parse(token) : null;
    } catch (error) {
      console.error('Error getting token:', error);
      return null;
    }
  },

  clearToken() {
    sessionStorage.removeItem('authData');
  },

  cleanAuthParams() {
    try {
      const url = new URL(window.location.href);
      url.searchParams.delete('time');
      url.searchParams.delete('userid');
      url.searchParams.delete('signature');
      const originalUrl = sessionStorage.getItem('originalUrl') || '/';
      sessionStorage.removeItem('originalUrl');
      window.location.href = originalUrl;
      window.dispatchEvent(new Event('search-restore'));
    } catch (error) {
      console.error('Error cleaning auth params:', error);
    }
  },
};
