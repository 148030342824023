import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import InputSelect from './InputSelect';
import { regions, categories, types } from '../../settings';
import { useStores } from '../../context/StoreContext';
import styled from 'styled-components';
import { ReactComponent as FilterIcon } from '../../assets/images/filterIcon.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/plusIcon.svg';
import { ReactComponent as MinusIcon } from '../../assets/images/minusIcon.svg';
import { useLanguageContext } from 'src/context/LanguageContext';
import {
  FilterCount,
  FilterGroup,
  FilterHeader,
  FilterTitle,
  FilterTitleWrapper,
  ButtonGroup,
  FilterButton,
  ClearFilterButton,
  MobileFilterOverlay,
  MobileFilterHeader,
  CloseButton,
  ClearFilterButtonMobile,
  DrawerContent,
  FilterHeaderButtons,
  SidebarContainer,
} from '../styled/Filters';

const FilterSidebar = observer(() => {
  const { searchStore, pdfStore } = useStores();
  const { form } = searchStore;
  const [openFilter, setOpenFilter] = useState<string | null>(null);
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);
  const { region, setRegion } = useLanguageContext();

  useEffect(() => {
    if (region) {
      pdfStore.setSelectedMarket(region);
    }
  }, [region, pdfStore]);

  const toggleFilter = (filterName: string) => {
    setOpenFilter(openFilter === filterName ? null : filterName);
  };

  const toggleMobileFilter = () => {
    setIsMobileFilterOpen(!isMobileFilterOpen);
  };

  const handleRegionSelect = (values: string[]) => {
    const selectedRegion = values.length > 0 ? values[values.length - 1] : null;
    setRegion(selectedRegion);
    pdfStore.setSelectedMarket(selectedRegion);
    searchStore.performSearch();
  };

  const clearAllFilters = () => {
    form.setType([]);
    form.setCategory([]);
    pdfStore.setSelectedMarket(null);
    setRegion(null);
    localStorage.setItem('hasVisitedBefore', 'true');
    localStorage.removeItem('selectedRegion');
    searchStore.performSearch();
    setOpenFilter(null);
  };

  const handleDocTypeSelect = (values: string[]) => {
    form.setType(values);
    searchStore.performSearch();
  };

  const handleCategorySelect = (values: string[]) => {
    form.setCategory(values);
    searchStore.performSearch();
  };

  const getActiveFilterCount = (filterValue: string[] | string | null) => {
    if (Array.isArray(filterValue)) {
      return filterValue.length;
    }
    return filterValue ? 1 : 0;
  };

  const isFilterActive = () => {
    return (
      form.type.length > 0 ||
      form.category.length > 0 ||
      form.region !== '' ||
      form.language !== '' ||
      pdfStore.selectedMarket !== null
    );
  };

  const renderFilterGroup = (
    title: string,
    content: React.ReactNode,
    filterValue: string[] | string | null
  ) => {
    const activeCount = getActiveFilterCount(filterValue);
    const isOpen = openFilter === title;

    return (
      <FilterGroup>
        <FilterHeader onClick={() => toggleFilter(title)} isOpen={isOpen}>
          <FilterTitle>{title}</FilterTitle>
          {activeCount > 0 && <FilterCount>({activeCount})</FilterCount>}
          {isOpen ? <MinusIcon /> : <PlusIcon />}
        </FilterHeader>
        {isOpen && content}
      </FilterGroup>
    );
  };

  const MobileFilterDrawer = styled.div<{ isOpen: boolean }>`
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 10px;
    width: 95%;
    background: ${({ theme: { colors } }) => colors.esabWhite};
    padding: 10px;
    transform: translateY(${({ isOpen }) => (isOpen ? '0' : '100%')});
    transition: transform 0.3s ease-in-out;
    z-index: 999;
    max-height: 80vh;
    overflow-y: auto;

    ${FilterHeader} {
      width: 100%;
    }
  `;

  const filterContent = (
    <>
      {renderFilterGroup(
        'Document type',
        <InputSelect
          name="type"
          values={types}
          value={form.type}
          onChange={handleDocTypeSelect}
        />,
        form.type
      )}
      {renderFilterGroup(
        'Region',
        <InputSelect
          name="region"
          values={regions}
          value={region ? [region] : []}
          onChange={handleRegionSelect}
        />,
        region
      )}
      {renderFilterGroup(
        'Product category',
        <InputSelect
          name="category"
          values={categories}
          value={form.category}
          onChange={handleCategorySelect}
        />,
        form.category
      )}
    </>
  );

  return (
    <>
      <SidebarContainer>
        <FilterHeaderButtons>
          <FilterTitleWrapper>
            <FilterIcon />
            <span>Filter</span>
          </FilterTitleWrapper>
          <ButtonGroup>
            <FilterButton onClick={toggleMobileFilter} isActive={true}>
              Filter
            </FilterButton>
            <ClearFilterButton
              onClick={clearAllFilters}
              isActive={isFilterActive()}
            >
              Clear All
            </ClearFilterButton>
          </ButtonGroup>
        </FilterHeaderButtons>
        {filterContent}
      </SidebarContainer>
      <MobileFilterOverlay
        isOpen={isMobileFilterOpen}
        onClick={toggleMobileFilter}
      />
      <MobileFilterDrawer isOpen={isMobileFilterOpen}>
        <MobileFilterHeader>
          <span>Filters</span>
          <CloseButton onClick={toggleMobileFilter}>&times;</CloseButton>
        </MobileFilterHeader>
        <ClearFilterButtonMobile
          onClick={clearAllFilters}
          isActive={isFilterActive()}
        >
          Clear All
        </ClearFilterButtonMobile>
        <DrawerContent>{filterContent}</DrawerContent>
      </MobileFilterDrawer>
    </>
  );
});

export default FilterSidebar;
