import React, { useState } from 'react';
import Modal from './Modal';
import { useStores } from '../../context/StoreContext';
import { useLanguageContext } from 'src/context/LanguageContext';
import { Region } from 'src/hooks/useLanguage';
import { RegionItemWrapper, RegionName, ModalContent, ModalRow, IconWrapper } from '../styled/SelectModal';

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

interface RegionItemProps {
  name: string;
  code: string;
  onClick: (code: string) => void;
}

const RegionItem: React.FC<RegionItemProps> = ({ name, code, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { region: selectedRegion } = useLanguageContext();
  const isSelected = selectedRegion === code;

  return (
    <RegionItemWrapper
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => onClick(code)}
      isHovered={isHovered}
      isSelected={isSelected}
    >
      <IconWrapper isHovered={isHovered} isSelected={isSelected}>
        <RegionName isSelected={isSelected}>{name}</RegionName>
      </IconWrapper>
    </RegionItemWrapper>
  );
};

const SelectModal: React.FC<Props> = ({ isVisible, onClose }) => {
  const { pdfStore } = useStores();
  const { setRegion } = useLanguageContext();

  const handleRegionSelect = (region: Region) => {
    setRegion(region);
    pdfStore.setSelectedMarket(region);
    localStorage.setItem('hasVisitedBefore', 'true');
    onClose();
  };

  return (
    <Modal isVisible={isVisible} onClose={onClose} title="Select your region">
      <ModalContent>
        <ModalRow>
          <RegionItem
            name="Asia Pacific"
            code="AS"
            onClick={handleRegionSelect}
          />
          <RegionItem name="Europe" code="EU" onClick={handleRegionSelect} />
          <RegionItem
            name="Middle East & Africa"
            code="ME"
            onClick={handleRegionSelect}
          />
          <RegionItem
            name="North America"
            code="NA"
            onClick={handleRegionSelect}
          />
          <RegionItem
            name="South America"
            code="SA"
            onClick={handleRegionSelect}
          />
          <RegionItem name="India" code="IN" onClick={handleRegionSelect} />
          <RegionItem name="Australia" code="AU" onClick={handleRegionSelect} />
        </ModalRow>
      </ModalContent>
    </Modal>
  );
};

export default SelectModal;
