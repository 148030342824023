import * as React from 'react';
import styled from 'styled-components';
import { GAEvent } from '../helpers/analytics';
import Button from './Button';
import FeedbackModal from './modals/FeedbackModal';
import { useTranslation } from '../../hooks/useTranslation';

const Wrapper = styled.div``;

const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.m};
  line-height: 24px;
  padding: 13px 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 13px;
`;

const FeedBack: React.FC = () => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);
  const clickHandler = React.useCallback((show: boolean): void => {
    setShowModal(show);
  }, []);

  return (
    <>
      <Wrapper>
        <Text>{t('feedback.text')}</Text>
        <ButtonWrapper>
          <Button
            text={t('feedback.buttonUp')}
            icon={<Icon src="/images/thumbs-up.svg" />}
            onClick={() => {
              GAEvent('Feedback', 'Provided positive feedback', 1);
              clickHandler(true);
            }}
          />
          <Button
            text={t('feedback.buttonDown')}
            icon={<Icon src="/images/thumbs-down.svg" />}
            onClick={() => {
              GAEvent('Feedback', 'Provided negative feedback', 0);
              clickHandler(true);
            }}
          />
        </ButtonWrapper>
        <FeedbackModal
          isVisible={showModal}
          closeHandler={() => clickHandler(false)}
        />
      </Wrapper>
    </>
  );
};

export default FeedBack;
