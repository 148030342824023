import * as React from 'react';
import { useQuery } from 'react-query';
import { useConfig } from './useAuth';
import axios from 'axios';
import { useParams } from 'react-router-dom';

export function useTranslation() {
  const config = useConfig();

  const { manifestId } = useParams<{ manifestId: string }>();

  const { data } = useQuery<any>('strings', () =>
    axios({
      method: 'get',
      url: `/manifests/${manifestId}/strings.json`,
      baseURL: process.env.REACT_APP_ESAB_STORAGE_URL,
    }).then((response) => {
      const returnData = {};
      Object.keys(response.data).map((key) => {
        returnData[key.toLocaleLowerCase()] = response.data[key];
      });
      return returnData;
    })
  );

  const t = React.useCallback(
    (key: string) => {
      if (data) {
        if (!data[key.toLocaleLowerCase()]) {
          return key;
        }

        return data[key.toLocaleLowerCase()];
      }
    },
    [data]
  );

  return { t };
}
