import styled from "styled-components";
import { ReactComponent as DropdownArrowClosed } from '../../assets/images/dropdownArrowClosed.svg';
import { ReactComponent as DropdownArrowOpen } from '../../assets/images/dropdownArrowOpen.svg';

export const SortButton = styled.div`
  font-size: 16px;
  border: 1px solid ${({ theme: { colors } }) => colors.esabButtonBorder};
  border-radius: 4px;
  cursor: pointer;
  height: 36px;
  padding: 0 30px 0 10px;
  color: ${({ theme: { colors } }) => colors.esabBlackGray};
  background: ${({ theme: { colors } }) => colors.esabWhite};
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.esabLightGray};
  }
`;

export const DropdownList = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid ${({ theme: { colors } }) => colors.esabButtonBorder};
  border-top: none;
  background: ${({ theme: { colors } }) => colors.esabWhite};
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
`;

export const DropdownItem = styled.div`
  padding: 8px 10px;
  cursor: pointer;
  font-size: 16px;
  color: ${({ theme: { colors } }) => colors.esabBlackGray};

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.esabLightGray};
  }
`;

export const StyledDropdownArrowClosed = styled(DropdownArrowClosed)`
  width: 12px;
  height: 12px;
  fill: ${({ theme: { colors } }) => colors.esabBlackGray};
`;

export const StyledDropdownArrowOpen = styled(DropdownArrowOpen)`
  width: 12px;
  height: 12px;
  fill: ${({ theme: { colors } }) => colors.esabBlackGray};
`;

export const DropdownArrowWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;

  svg {
    width: 12px;
    height: 12px;
    fill: ${({ theme: { colors } }) => colors.esabBlackGray};
  }
`;

export const ResultsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 90px;
    margin-top: 20px;
  }
`;

export const SearchResultInfo = styled.div`
  font-size: 16px;
  width: 70%;
  color: ${({ theme: { colors } }) => colors.esabTrueBlack};

  span {
    font-weight: 700;
    text-transform: capitalize;
    letter-spacing: 1.6px;
  }

  @media (max-width: 768px) {
    width: 75%;
    font-size: 14px;
    margin: 10px 0 10px 20px;
  }
`;

export const SortContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: flex-start;
    margin-left: 10px;
  }
`;

export const SortLabel = styled.span`
  margin-right: 10px;
  font-size: 16px;
  color: ${({ theme: { colors } }) => colors.esabBlackGray};

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const SortSelectWrapper = styled.div`
  position: relative;
  width: 230px;

  @media (max-width: 768px) {
    width: 100%;
    max-width: 230px;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  padding-top: 20px;
  justify-content: center;
  margin: 50px 50px 0;

  @media (max-width: 768px) {
    padding-top: 10px;
    margin: 50px 10px 0;
    flex-direction: column;
  }
`;

export const MainContent = styled.div`
  flex-grow: 1;
  max-width: 1300px;

  @media (max-width: 768px) {
    padding-left: 0;
  }
`;

export const ResultsList = styled.div`
  > div {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    margin: 10px;
  }
`;

export const Instructions = styled.div`
  color: ${({ theme: { colors } }) => colors.esabTrueBlack};
  display: flex;
  flex-direction: column;
  margin: 32px auto 0 auto;

  @media (max-width: 768px) {
    margin: 0 auto;
    align-items: center;
  }
`;

export const InstructionsTopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 24px;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
`;

export const InstructionsTextContent = styled.div`
  flex: 1;
  padding-right: 40px;

  @media (max-width: 768px) {
    padding-right: 0;
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 75%;
    margin-left: 20px;
    margin-top: 20px;
  }
`;

export const InstructionsTitle = styled.h1`
  font-size: 32px;
  color: ${({ theme: { colors } }) => colors.esabTrueBlack};
  margin: 0 0 20px 0;

  @media (max-width: 768px) {
    font-size: 24px;
    margin: 0 0 16px 0;
  }
`;

export const InstructionsSubTitle = styled.p`
  font-size: 18px;
  color: ${({ theme: { colors } }) => colors.esabBlackGray};

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const InstructionsImageWrapper = styled.div`
  color: ${({ theme: { colors } }) => colors.esabTrueBlack};
  border: 2px dashed #888b8d;
  width: 100%;
  max-width: 585px;
  background: #fff;
  padding: 32px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    padding: 16px;
    max-width: 62%;
    flex-direction: column;
  }
`;

export const IconWrapper = styled.div`
  margin-right: 16px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    margin-right: 12px;
    svg {
      width: 40px;
      height: 40px;
    }
  }
`;

export const TextWrapper = styled.div`
  font-size: 16px;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const InstructionsTitleLink = styled.a`
  cursor: pointer;
  color: ${({ theme: { colors } }) => colors.esabGray};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const SpinContainer = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
`;

export const SpinContent = styled.div`
  position: absolute;
  top: 300px;
  left: 50%;
  margin-left: -50px;

  @media (max-width: 768px) {
    top: 200px;
  }
`;