import { makeAutoObservable } from 'mobx';
import { getUrlParameter, urlParameterIsTrue } from '../settings';

export default class UIStore {
  showRegionDropdown = false;
  showLogo = false;
  isSearchOpen = false;
  isMobileMenuOpen = false;

  constructor() {
    makeAutoObservable(this);
    this.showRegionDropdown = !getUrlParameter('region');
    this.showLogo = !urlParameterIsTrue('embedded');
  }

  toggleSearch = () => {
    this.isSearchOpen = !this.isSearchOpen;
    if (this.isSearchOpen) {
      this.isMobileMenuOpen = false;
    }
  };

  toggleMobileMenu = () => {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
    if (this.isMobileMenuOpen) {
      this.isSearchOpen = false;
    }
  };

  closeAllMobileMenus = () => {
    this.isSearchOpen = false;
    this.isMobileMenuOpen = false;
  };
}
