import React from 'react';
import { PdfStore, UIStore, SearchStore, AuthStore } from '../stores';

export type StoreContextType = {
  uiStore: UIStore;
  pdfStore: PdfStore;
  searchStore: SearchStore;
  authStore: AuthStore;
};

const StoreContext = React.createContext<StoreContextType | undefined>(
  undefined
);

const StoreProvider = StoreContext.Provider;

function useStores() {
  const context = React.useContext(StoreContext);
  if (!context) {
    throw new Error('useStores must be called within a StoreContextProvider');
  }
  return context;
}

export { StoreProvider, useStores };
