import { makeAutoObservable } from 'mobx';
import { categories, isOptionGroup, SelectItem } from '../settings';

export type LanguageVariant = {
  lang: string;
  url: string;
  manualtype: string[];
};

export interface PdfItem {
  '@search.score': number;
  documentno: string;
  language: string;
  manualtitle: string;
  manualtype: string[];
  metadata_storage_name: string;
  metadata_storage_path: string;
  metadata_storage_size: number;
  productcategory: string;
  releasedate: string;
  languagevariants?: LanguageVariant[];
  market: {
    [key: string]: boolean;
  };
  public: boolean;
  serialno: string;
  productgin: string;
}

interface ApiPdfItem extends Omit<PdfItem, 'manualtype'> {
  manualtype: string | string[];
}

export default class PdfStore {
  items: PdfItem[] = [];
  filteredItems: PdfItem[] = [];
  page = 1;
  totalCount = 0;
  itemsPerPage = 10;
  selectedMarket: string | null = null;

  constructor() {
    makeAutoObservable(this);
    const storedRegion = localStorage.getItem('selectedRegion');
    if (storedRegion) {
      this.selectedMarket = storedRegion;
    }
  }

  mapManualType(manualtype: string | string[]): string[] {
    if (Array.isArray(manualtype)) {
      return manualtype;
    }

    if (typeof manualtype === 'string' && manualtype.includes(',')) {
      return manualtype.split(',').map((t) => t.trim());
    }

    return [manualtype];
  }

  mapCategory(category: string): string {
    const findCategory = (items: SelectItem[]): string | undefined => {
      for (const item of items) {
        if (isOptionGroup(item)) {
          const found = findCategory(item.items);
          if (found) return found;
        } else if (item.key === category) {
          return item.value;
        }
      }
      return undefined;
    };

    const categoryValue = findCategory(categories);
    return categoryValue || category;
  }

  setItems(items: ApiPdfItem[]) {
    this.items = items.map((item) => ({
      ...item,
      manualtype: this.mapManualType(item.manualtype),
      productcategory: this.mapCategory(item.productcategory),
    }));
    this.filterItems();
  }

  setPage(page: number) {
    this.page = page;
  }

  setTotalCount(count: number) {
    this.totalCount = count;
  }

  setSelectedMarket(market: string | null) {
    this.selectedMarket = market;
    if (market) {
      localStorage.setItem('selectedRegion', market);
    } else {
      localStorage.removeItem('selectedRegion');
    }
    this.filterItems();
  }

  filterItems() {
    if (this.selectedMarket) {
      this.filteredItems = this.items.filter(
        (item) => item.market[this.selectedMarket!]
      );
    } else {
      this.filteredItems = this.items;
    }
    this.setTotalCount(this.filteredItems.length);
  }

  get pages() {
    return Math.ceil(this.totalCount / this.itemsPerPage);
  }
}
