import { useState, useEffect } from 'react';
import { regions } from '../settings';

export type Region = (typeof regions)[number]['key'];
export type Language = string;

const REGION_STORAGE_KEY = 'selectedRegion';
const LANGUAGE_STORAGE_KEY = 'selectedLanguage';
const FIRST_VISIT_KEY = 'hasVisitedBefore';

export default function useLanguage() {
  const [region, setRegion] = useState<Region | null>(() => {
    const storedRegion = localStorage.getItem(REGION_STORAGE_KEY);
    return storedRegion as Region | null;
  });

  const [language, setLanguage] = useState<Language | null>(() => {
    return localStorage.getItem(LANGUAGE_STORAGE_KEY);
  });

  const [isFirstVisit, setIsFirstVisit] = useState(() => {
    const hasVisited = localStorage.getItem(FIRST_VISIT_KEY);
    return hasVisited !== 'true';
  });

  useEffect(() => {
    if (isFirstVisit && region) {
      localStorage.setItem(FIRST_VISIT_KEY, 'false');
      setIsFirstVisit(false);
    }
  }, [isFirstVisit, region]);

  useEffect(() => {
    if (region) {
      localStorage.setItem(REGION_STORAGE_KEY, region);
    } else {
      localStorage.removeItem(REGION_STORAGE_KEY);
    }
  }, [region]);

  useEffect(() => {
    if (language) {
      localStorage.setItem(LANGUAGE_STORAGE_KEY, language);
    } else {
      localStorage.removeItem(LANGUAGE_STORAGE_KEY);
    }
  }, [language]);

  const clearRegionAndLanguage = () => {
    setRegion(null);
    setLanguage(null);
    localStorage.removeItem(REGION_STORAGE_KEY);
    localStorage.removeItem(LANGUAGE_STORAGE_KEY);
  };

  return {
    region,
    language,
    setRegion,
    setLanguage,
    clearRegionAndLanguage,
    isFirstVisit,
  };
}
