import * as React from 'react';
import styled from 'styled-components';
import H1 from './typography/H1';
import LinkList from './LinkList';
import { useNodes } from '../../hooks/useNodes';
import Loader from './Loader';
import StringToComponent from './StringToComponent';

const MainContent = styled.div<{ isLoading: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ isLoading }) => isLoading && 'center'};
  flex: 1;
  max-width: 640px;
  padding-right: 10px;

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    max-width: 100%;
    padding-right: 0;
    margin-bottom: ${({ theme }) => theme.space.l};
  }
`;

const Summary = styled.div`
  margin-bottom: 40px;
  max-width: 640px;
  width: 100%;
`;

const StyledH1 = styled(H1)`
  text-transform: uppercase;
  margin-bottom: 48px;
`;

type BrowseContentProps = {
  parentNode: Esab.Node;
  path: string;
  childId?: string;
  loading: boolean;
};

const BrowseContent: React.FC<BrowseContentProps> = ({
  parentNode,
  childId,
  path,
  loading,
}) => {
  const { node } = useNodes(parentNode, childId, path);

  if (node === undefined) {
    return null;
  }

  return (
    <MainContent isLoading={loading}>
      {loading ? (
        <Loader />
      ) : (
        <>
          {!node.overview && <StyledH1>{node.title}</StyledH1>}
          {node.overview && (
            <Summary>
              <StringToComponent data={node.overview} />
            </Summary>
          )}
          {node.children && node.children.length > 0 && (
            <LinkList parent={path} items={node.children} />
          )}
        </>
      )}
    </MainContent>
  );
};

export default BrowseContent;
