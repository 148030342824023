import styled from "styled-components";

export const SuggestionsBox = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 390px;
  overflow-y: auto;

  @media (max-width: 768px) {
    max-height: 40vh;
  }
`;

export const SuggestionsListItem = styled.li<{ isSelected: boolean }>`
  cursor: pointer;
  padding: 20px;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.esabLightGray : theme.colors.esabWhite};

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.esabLightGray};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.esabLightGray};
  }

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

export const SuggestionTitle = styled.div`
  margin-bottom: 4px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const SuggestionType = styled.div`
  color: ${({ theme }) => theme.colors.esabCoolGray};
  font-size: 12px;

  @media (max-width: 768px) {
    font-size: 11px;
  }
`;