import React, { FormEvent, useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import InputText from './InputText';
import Suggestions from './Suggestions';
import { searchApi } from 'src/services/api';
import { useStores } from 'src/context/StoreContext';
import {
  SearchInputWrapper,
  SuggestionsWrapper,
  SearchButton,
  Form,
} from '../styled/SearchForm';

interface Suggestion {
  text: string;
  manualtitle: string;
  productcategory: string;
  manualtype: string;
}

const SearchForm = observer(() => {
  const { searchStore, uiStore } = useStores();
  const { form } = searchStore;
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    searchStore.performSearch();
    setShowSuggestions(false);
    uiStore.closeAllMobileMenus();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    form.setText(value);
    setShowSuggestions(true);
  };

  const handleInputClick = () => {
    if (form.text && suggestions.length > 0) {
      setShowSuggestions(true);
    }
  };

  const handleSuggestionClick = (suggestion: Suggestion) => {
    form.setText(`"${suggestion.text}"`);
    setShowSuggestions(false);
    searchStore.performSearch();
    uiStore.closeAllMobileMenus();
  };

  const handleEnterPress = () => {
    setShowSuggestions(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!showSuggestions || suggestions.length === 0) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex((prev) =>
          prev < suggestions.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex((prev) => (prev > 0 ? prev - 1 : prev));
        break;
      case 'Enter':
        e.preventDefault();
        if (selectedIndex >= 0) {
          const selectedSuggestion = suggestions[selectedIndex];
          form.setText(`"${selectedSuggestion.text}"`);
          setShowSuggestions(false);
          searchStore.performSearch();
        } else {
          onSubmit(e as unknown as FormEvent<HTMLFormElement>);
        }
        break;
      case 'Escape':
        setShowSuggestions(false);
        setSelectedIndex(-1);
        break;
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (form.text) {
        fetchSuggestions(form.text);
      } else {
        setSuggestions([]);
      }
    }, 50);

    return () => clearTimeout(timer);
  }, [form.text]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setShowSuggestions(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  const fetchSuggestions = async (query: string) => {
    try {
      const response = await searchApi.post(
        `${process.env.REACT_APP_AZURE_SUGGEST_URL}`,
        {
          search: query,
          fuzzy: true,
          select: 'manualtitle,manualtype',
          suggesterName: 'titledocno',
          top: 15,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'api-key': process.env.REACT_APP_AZURE_API_KEY,
          },
        }
      );

      const manualTitleToUpperCase = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      };

      const suggestionsMap = new Map<string, Suggestion>();

      response.data.value.forEach((item: any) => {
        const lowercaseTitle = item.manualtitle.toLowerCase();
        const capitalizedTitle = manualTitleToUpperCase(item.manualtitle);

        if (!suggestionsMap.has(lowercaseTitle)) {
          suggestionsMap.set(lowercaseTitle, {
            text: capitalizedTitle,
            manualtitle: capitalizedTitle,
            productcategory: '',
            manualtype: '',
          });
        }
      });

      const uniqueSuggestions: Suggestion[] = Array.from(
        suggestionsMap.values()
      );

      setSuggestions(uniqueSuggestions);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setSuggestions([]);
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <SearchInputWrapper ref={wrapperRef}>
        <InputText
          title=""
          name="text"
          value={form.text}
          onChange={handleInputChange}
          onClick={handleInputClick}
          onEnterPress={handleEnterPress}
          onKeyDown={handleKeyDown}
        />
        <SuggestionsWrapper showSuggestions={showSuggestions}>
          <Suggestions
            suggestions={suggestions}
            onSuggestionClick={handleSuggestionClick}
            visible={showSuggestions}
            selectedIndex={selectedIndex}
            onKeyNavigation={setSelectedIndex}
          />
        </SuggestionsWrapper>
      </SearchInputWrapper>
      <SearchButton type="submit">Search</SearchButton>
    </Form>
  );
});

export default SearchForm;
