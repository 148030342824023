import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { PdfItem as PdfItemType } from '../stores/PdfStore';
import { ReactComponent as SiteIcon } from '../assets/images/site.svg';
import { ReactComponent as PdfIcon } from '../assets/images/pdf.svg';
import { getUrlParameter, regions, languages, types } from '../settings';
import { keys } from 'mobx';
import TruncatedTitle, { ArticleTruncatedTitle } from './Truncated';
import LoginModal from './Modal/LoginModal';
import { useStores } from '../context/StoreContext';
import {
  Container,
  ColumnWrapper,
  Column,
  Item,
  ItemTitle,
  Link,
  ButtonIcon,
  Description,
  Type,
  Details,
  LanguageDropdownWrapper,
  DropdownButton,
  DropdownArrowWrapper,
  DropdownList,
  DropdownItem,
  StyledDropdownArrowOpen,
  StyledDropdownArrowClosed,
} from './styled/PdfItem';
import { useManualUrl } from 'src/hooks/useManualUrl';
import { Link as RouterLink } from 'react-router-dom';

type Props = {
  pdf: PdfItemType;
};

const PdfItem = observer(({ pdf }: Props) => {
  const { authStore } = useStores();
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const { getManualUrl } = useManualUrl();

  useEffect(() => {
    if (pdf.languagevariants && pdf.languagevariants.length > 0) {
      const enGB = pdf.languagevariants.find(
        (variant) => variant.lang === 'en-GB'
      );
      if (enGB) {
        setSelectedLanguage(enGB.url);
      } else {
        setSelectedLanguage(pdf.languagevariants[0].url);
      }
    } else {
      setSelectedLanguage(pdf.metadata_storage_path);
    }
  }, [pdf]);

  const getManualTypeNames = (manualTypes: string[]) => {
    return manualTypes
      .map((type) => {
        const typeObj = types.find((t) => t.key === type);
        return typeObj ? typeObj.value : type;
      })
      .join(', ');
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getLink = () => {
    if (!pdf.languagevariants || pdf.languagevariants.length === 0) {
      return pdf.metadata_storage_path;
    }

    const selectedUrl = selectedLanguage || pdf.languagevariants[0].url;

    const isHtmlManual = pdf.manualtype.includes('HTML');

    if (isHtmlManual) {
      const urlParts = selectedUrl.split('/');
      const manifestId = urlParts[urlParts.length - 1];

      return `/html/${manifestId}`;
    }

    const userParam = getUrlParameter('user');
    return userParam ? `${selectedUrl}?user=${userParam}` : selectedUrl;
  };

  const handleLanguageChange = (url: string) => {
    setSelectedLanguage(url);
    setIsDropdownOpen(false);
  };

  const getRegions = () => {
    const regionKeys = keys(pdf.market).filter(
      (key): key is keyof typeof pdf.market =>
        typeof key === 'string' && pdf.market[key as keyof typeof pdf.market]
    );

    const regionNames = regionKeys.map((key) => {
      const region = regions.find((r) => r.key === key);
      return region ? region.value : key;
    });

    return regionNames.join(', ');
  };

  const getLanguageName = (langCode: string) => {
    const language = languages.find((lang) => lang.key === langCode);
    return language ? language.value : langCode;
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const day = date.getDate().toString().padStart(2, '0');
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const handleLoginSuccess = () => {
    setShowLoginModal(false);
    window.location.href = process.env.REACT_APP_ESAB_LOGIN_URL;
  };

  const handleCreateAccount = () => {
    setShowLoginModal(false);
    window.location.href = process.env.REACT_APP_ESAB_LOGIN_URL;
  };

  const getSecureLink = () => {
    const baseUrl = getLink();
    if (pdf.public) return baseUrl;
    return authStore.getSecuredUrl(baseUrl);
  };

  const handlePdfClick = (e: React.MouseEvent) => {
    e.preventDefault();

    if (!pdf.public && !authStore.isAuthenticated) {
      setShowLoginModal(true);
      return;
    }

    window.open(getSecureLink(), '_blank');
  };

  const handleAuxClick = (e: React.MouseEvent) => {
    if (!pdf.public) {
      e.preventDefault();
      setShowLoginModal(true);
    }
  };

  const getShortManualTypeNames = (manualTypes: string[]) => {
    return manualTypes
      .map((type) => {
        const typeObj = types.find((t) => t.key === type);
        return type;
      })
      .join(', ');
  };

  const isHtmlManual = pdf.manualtype.includes('HTML');

  return (
    <Container>
      <ColumnWrapper>
        <Column size="large">
          <TruncatedTitle title={pdf.manualtitle} maxLength={80} />
          <Description>{pdf.productcategory}</Description>
          <Type>{getManualTypeNames(pdf.manualtype)}</Type>
        </Column>
        <Column>
          <Item>
            <ItemTitle>Region</ItemTitle>
            <div>{getRegions()}</div>
          </Item>
          <Item>
            <ItemTitle>Language and variants</ItemTitle>
            {pdf.languagevariants && pdf.languagevariants.length > 0 ? (
              <LanguageDropdownWrapper ref={dropdownRef}>
                <DropdownButton
                  onClick={() =>
                    pdf.languagevariants.length > 1 &&
                    setIsDropdownOpen(!isDropdownOpen)
                  }
                >
                  <span className="language">
                    {getLanguageName(
                      pdf.languagevariants.find(
                        (v) => v.url === selectedLanguage
                      )?.lang || ''
                    )}
                  </span>{' '}
                  <span className="short-type">
                    {getShortManualTypeNames(
                      pdf.languagevariants.find(
                        (v) => v.url === selectedLanguage
                      )?.manualtype || []
                    )}
                  </span>{' '}
                  <span className="date">{formatDate(pdf.releasedate)}</span>
                  {pdf.languagevariants.length > 1 && (
                    <DropdownArrowWrapper>
                      {isDropdownOpen ? (
                        <StyledDropdownArrowOpen />
                      ) : (
                        <StyledDropdownArrowClosed />
                      )}
                    </DropdownArrowWrapper>
                  )}
                </DropdownButton>
                {isDropdownOpen && (
                  <DropdownList>
                    {pdf.languagevariants.map((variant, index) => (
                      <DropdownItem
                        key={index}
                        onClick={() => handleLanguageChange(variant.url)}
                      >
                        <div className="dropdown-content">
                          <div className="main-info">
                            <span className="language">
                              {getLanguageName(variant.lang)}
                            </span>
                            <span className="date">
                              {formatDate(pdf.releasedate)}
                            </span>
                          </div>
                          <div className="full-type">
                            {getManualTypeNames(variant.manualtype)}
                          </div>
                        </div>
                      </DropdownItem>
                    ))}
                  </DropdownList>
                )}
              </LanguageDropdownWrapper>
            ) : (
              <div>{getLanguageName(pdf.language)}</div>
            )}
          </Item>
        </Column>
        <Column>
          {isHtmlManual ? (
            <Link as={RouterLink} to={getLink()} className="pdf-link">
              <ButtonIcon>
                <SiteIcon />
              </ButtonIcon>
              OPEN WEBSITE
            </Link>
          ) : (
            <Link
              onAuxClick={handleAuxClick}
              href={getLink()}
              onClick={handlePdfClick}
              target="_blank"
              className="pdf-link"
            >
              <ButtonIcon>
                <PdfIcon />
              </ButtonIcon>
              OPEN PDF
            </Link>
          )}
        </Column>
      </ColumnWrapper>
      <Details>
        {pdf.productgin && (
          <>
            Article No: <ArticleTruncatedTitle title={pdf.productgin} /> -{' '}
          </>
        )}
        Document No: {pdf.documentno}
        {pdf.serialno && ` - Serial No: ${pdf.serialno}`} - Release Date:{' '}
        {formatDate(pdf.releasedate)}
      </Details>
      <LoginModal
        isVisible={showLoginModal}
        onClose={() => setShowLoginModal(false)}
        onLogin={handleLoginSuccess}
        onCreateAccount={handleCreateAccount}
      />
    </Container>
  );
});

export default PdfItem;
