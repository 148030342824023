import { useEffect } from 'react';

declare global {
  interface Window {
    _mtm: any[];
  }
}

export const useMatomo = () => {
  useEffect(() => {
    // Initialize
    window._mtm = window._mtm || [];
    window._mtm.push({
      'mtm.startTime': new Date().getTime(),
      event: 'mtm.Start',
    });

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src =
      'https://cdn.matomo.cloud/esab.matomo.cloud/container_HruOiAlE.js';

    document.head.appendChild(script);

    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);
};
