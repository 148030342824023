import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import Breadcrumbs from '../Breadcrumbs';
import Page from '../layout/Page';
import { PageContent } from '../layout/PageContent';
import PageContentWrapper from '../layout/PageContentWrapper';
import { useFetchNode } from '../../../hooks/useFetchData';
import BrowseContent from '../BrowseContent';
import { useNodes } from '../../../hooks/useNodes';

const Browse = () => {
  const { childId, path } = useParams<{
    childId: string;
    path: string;
    manifestId: string;
  }>();
  const { isLoading, error, data } = useFetchNode(
    `${path.charAt(0).toUpperCase() + path.slice(1)}.json `
  );
  const { breadcrumbs } = useNodes(data, childId, path);

  if (data?.status === 404) {
    return <Redirect to="/404" />;
  }

  return (
    <Page>
      <PageContentWrapper>
        <PageContent>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          {!error ? (
            <BrowseContent
              parentNode={data}
              childId={childId}
              path={path}
              loading={isLoading}
            />
          ) : (
            <div>Error message</div>
          )}
        </PageContent>
      </PageContentWrapper>
    </Page>
  );
};

export default Browse;
