import styled from "styled-components";
import { ReactComponent as SearchIcon } from '../../assets/images/searchIcon.svg';
import { ReactComponent as ClearTextIcon } from '../../assets/images/clearText.svg';

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;

  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
  }
`;

export const ClearTextButton = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    right: 50px;
  }
`;

export const StyledClearTextIcon = styled(ClearTextIcon)`
  width: 24px;
  height: 24px;

  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
  }
`;