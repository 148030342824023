import React, { FC } from 'react';
import styled from 'styled-components';
import Footer from '../Footer';
import Header from '../Header';
import HtmlManualWrapper from 'src/components/helpers/HtmlManualWrapper';

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  width: 100%;
`;

const Content = styled.div`
  flex: 1;
  justify-content: top;
  flex-direction: column;
  display: flex;
  padding-bottom: 80px;
`;

type PageProps = {
  hideSearch?: boolean;
};

const Page: FC<PageProps> = (props) => (
  <>
    <HtmlManualWrapper>
      <Wrapper>
        <Header hideSearch={props.hideSearch} />
        <Content>{props.children}</Content>
        <Footer />
      </Wrapper>
    </HtmlManualWrapper>
  </>
);

export default Page;
