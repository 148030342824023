import { makeAutoObservable } from 'mobx';
import { createModelSchema, primitive, list } from 'serializr';

export default class Form {
  text = '';
  type: string[] = [];
  category: string[] = [];
  region = '';
  language = '';
  strictRegionChecking = false;
  orderBy = '';
  subcategory = '';

  constructor() {
    makeAutoObservable(this);
  }

  setText = (text: string) => {
    this.text = text;
  };

  setType = (type: string[]) => {
    this.type = type;
  };

  setCategory = (category: string[]) => {
    this.category = category;
  };

  setSubcategory = (subcategory: string) => {
    this.subcategory = subcategory;
  };

  setRegion = (region: string) => {
    this.region = region;
  };

  setLanguage = (language: string) => {
    this.language = language;
  };

  setOrderby = (orderBy: string) => {
    this.orderBy = orderBy;
  };
  setStrictRegionChecking = (strictRegionChecking: boolean) => {
    this.strictRegionChecking = strictRegionChecking;
  };

  get trimmedValues() {
    return {
      text: this.text.trim(),
      type: this.type,
      category: this.category,
      subcategory: this.subcategory.trim(),
      region: this.region.trim(),
      language: this.language.trim(),
      orderBy: this.orderBy.trim(),
      strictRegionChecking: this.strictRegionChecking,
    };
  }
}

createModelSchema(Form, {
  text: primitive(),
  type: list(primitive()),
  category: list(primitive()),
  subcategory: primitive(),
  region: primitive(),
  language: primitive(),
  strictRegionChecking: primitive(),
  orderBy: primitive(),
});
