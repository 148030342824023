import React, { createContext, ReactNode, useContext } from 'react';
import useLanguage from 'src/hooks/useLanguage';

interface RegionLangTypes {
  children: ReactNode;
}

export const LanguageContext = createContext<
  ReturnType<typeof useLanguage> | undefined
>(undefined);

export const RegionLanguageProvider: React.FC<RegionLangTypes> = ({
  children,
}) => {
  const languageState = useLanguage();

  return (
    <LanguageContext.Provider value={languageState}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguageContext = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error(
      'useLanguageContext must be used within a RegionLanguageProvider'
    );
  }
  return context;
};
