import { makeAutoObservable } from 'mobx';
import { authService, AuthParams, AuthResponse } from '../services/authService';

export default class AuthStore {
  accessToken: AuthResponse['accessToken'] | null = null;
  expiresOn: Date | null = null;
  isAuthenticated = false;
  isAuthenticating = false;

  constructor() {
    makeAutoObservable(this);
    this.checkAuthParams();
  }

  get isTokenValid(): boolean {
    if (!this.expiresOn || !this.accessToken) return false;
    return new Date() < this.expiresOn;
  }

  async checkAuthParams() {
    const params = authService.getAuthParamsFromUrl();
    if (params) {
      await this.handleAuthentication(params);
    }
  }

  async handleAuthentication(params: AuthParams) {
    try {
      this.isAuthenticating = true;
      const response = await authService.authenticate(params);
      this.setAuthData(response.accessToken, response.expiresOn);
      authService.saveToken(response);
      window.dispatchEvent(new Event('search-restore'));
      authService.cleanAuthParams();
    } catch (error) {
      console.error('Authentication failed:', error);
      this.clear();
    } finally {
      this.isAuthenticating = false;
    }
  }

  setAuthData(accessToken: AuthResponse['accessToken'], expiresOn: string) {
    this.accessToken = accessToken;
    this.expiresOn = new Date(expiresOn);
    this.isAuthenticated = true;
  }

  getSecuredUrl(url: string): string {
    if (!this.isTokenValid || !this.accessToken) return url;
    return authService.appendTokenToUrl(url, this.accessToken);
  }

  initFromStorage() {
    const storedAuth = authService.getToken();
    if (storedAuth) {
      this.setAuthData(storedAuth.accessToken, storedAuth.expiresOn);
    }
  }

  clear() {
    this.accessToken = null;
    this.expiresOn = null;
    this.isAuthenticated = false;
    authService.clearToken();
  }
}
