import { useQuery, UseBaseQueryResult } from 'react-query';
import axios from 'axios';
import { useConfig } from './useAuth';
import { useParams } from 'react-router-dom';

type Link = {
  title: string;
  imageId: string;
  target: string;
};

type HomePageData = {
  heroImageId: string;
  productImageId: string;
  description: string;
  title: string;
  featuredArticles: Link[];
};

type HomePageResponse = HomePageData & { status?: number };

export function useFetchStartPage(): UseBaseQueryResult<HomePageResponse> {
  const config = useConfig();
  const { manifestId } = useParams<{ manifestId: string }>();

  return useQuery<HomePageData>('startpage', () =>
    axios({
      method: 'get',
      url: `/manifests/${manifestId}/startpage.json`,
      baseURL: process.env.REACT_APP_ESAB_STORAGE_URL,
    })
      .then((response) => response.data)
      .catch((error) => error.response)
  );
}
export function useFetchSettings() {
  const config = useConfig();
  const { manifestId } = useParams<{ manifestId: string }>();

  return useQuery('settings', () =>
    axios({
      method: 'get',
      url: `/manifests/${manifestId}/settings.json`,
      baseURL: process.env.REACT_APP_ESAB_STORAGE_URL,
    })
      .then((response) => response.data)
      .catch((error) => error.response)
  );
}
type NodeResponse = Esab.Node & { status?: number };

export function useFetchNode(
  browseId: string
): UseBaseQueryResult<NodeResponse> {
  const config = useConfig();
  const { manifestId } = useParams<{ manifestId: string }>();

  return useQuery<NodeResponse>(['browse', browseId], () =>
    axios({
      method: 'get',
      url: `/manifests/${manifestId}/browse/${browseId}`,
      baseURL: process.env.REACT_APP_ESAB_STORAGE_URL,
    })
      .then((response) => response.data)
      .catch((error) => error.response)
  );
}

export function useFetchTopic(topicId: string) {
  const config = useConfig();
  const { manifestId } = useParams<{ manifestId: string }>();

  return useQuery<string>(['topic', topicId], () =>
    axios({
      method: 'get',
      url: `/manifests/${manifestId}/html/${topicId}.html`,
      baseURL: process.env.REACT_APP_ESAB_STORAGE_URL,
    }).then((response) => response.data)
  );
}
