import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from '../../hooks/useTranslation';
import Anchor from './topic/Anchor';

const Wrapper = styled.div`
  background-color: ${(p) => p.theme.colors.lightGrey};
  padding: 17px 0;
  display: flex;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    padding: 17px 20px;
  }
`;

const Menu = styled.ul`
  display: flex;

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    flex-wrap: wrap;
  }
`;

const Item = styled.li`
  padding-right: 5px;
  color: ${({ theme }) => theme.colors.esabBlack};
`;

const Divider = styled.span`
  padding-left: 5px;
`;

const Footer: React.FC = () => {
  const { t } = useTranslation();

  const ApptextOrAnchor = (key: string) => {
    const prefix = 'footer.item.';
    const fullKey = prefix + key;
    const urlKey = fullKey + '.url';

    if (t(urlKey) !== urlKey) {
      return (
        <Anchor href={t(urlKey)} data={t(fullKey)}>
          {}
        </Anchor>
      );
    }

    return t(fullKey);
  };

  const items: { translationKey: string }[] = [
    { translationKey: 'copyright' },
    { translationKey: 'webSite' },
    { translationKey: 'about' },
    { translationKey: 'privacyPolicy' },
    { translationKey: 'contact' },
    { translationKey: 'documentationMail' },
  ];
  return (
    <>
      <Wrapper>
        <Menu>
          {items.map((item, index) => (
            <Item key={index}>
              {ApptextOrAnchor(item.translationKey)}
              {items.length - 1 !== index && <Divider>|</Divider>}
            </Item>
          ))}
        </Menu>
      </Wrapper>
    </>
  );
};

export default Footer;
