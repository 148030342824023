import React, { FC } from 'react';
import { useConfig } from 'src/hooks/useAuth';
import styled from 'styled-components';

const Image = styled.img`
  display: inline-block;

  td & {
    padding-right: ${({ theme }) => theme.space.xs};

    &:not(:only-child) {
      max-width: 49%;

      &:first-of-type {
        padding-right: unset;
      }
    }
  }
`;

type ImageProps = {
  imageId: string;
  node: Esab.Topic;
};

const Img: FC<ImageProps> = ({ node, imageId }) => {
  const config = useConfig();
  return (
    <>
      <Image
        className={node.attribs.class}
        width={node.attribs.width && node.attribs.width}
        src={
          process.env.REACT_APP_ESAB_STORAGE_URL + "/images/" +
          imageId
        }
        alt={node.attribs.alt}
      />
    </>
  );
};

export default Img;
