import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import Content from './styled/Content';
import SearchForm from './Search/SearchForm';
import logo from '../assets/images/logo.png';
import { ReactComponent as InfoIcon } from '../assets/images/info.svg';
import { ReactComponent as LoginIcon } from '../assets/images/user.svg';
import { ReactComponent as GlobeIcon } from '../assets/images/globe.svg';
import { ReactComponent as DropdownArrowClosed } from '../assets/images/dropdownArrowClosed.svg';
import { ReactComponent as DropdownArrowOpen } from '../assets/images/dropdownArrowOpen.svg';
import { ReactComponent as HamburgerIcon } from '../assets/images/hamburger.svg';
import { ReactComponent as SearchIcon } from '../assets/images/searchIcon.svg';
import { useStores } from '../context/StoreContext';
import { useLanguageContext } from '../context/LanguageContext';
import SelectModal from './Modal/SelectModal';
import InfoModal from './Modal/InfoModal';
import {
  ButtonText,
  DropdownArrowWrapper,
  FormWrapper,
  HeaderContainer,
  HeaderContent,
  IconButton,
  IconWrapper,
  LeftSection,
  Logo,
  LogoLink,
  MobileCenterSection,
  MobileLeftSection,
  MobileLogo,
  MobileMenu,
  MobileMenuItem,
  MobileRightSection,
  MobileSearchOverlay,
  RightSection,
  DisabledButton,
  DisabledMenuItem,
  SearchOverlayBackground,
} from './styled/Header';

const Header = observer(() => {
  const { uiStore, authStore } = useStores();
  const { region } = useLanguageContext();
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showSelectModal, setShowSelectModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      if (!mobile) {
        uiStore.closeAllMobileMenus();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [uiStore]);

  useEffect(() => {
    authStore.initFromStorage();
  }, [authStore]);

  const handleInfoModalToggle = () => {
    setShowInfoModal(!showInfoModal);
    uiStore.closeAllMobileMenus();
  };

  const handleSelectModalToggle = () => {
    setShowSelectModal(!showSelectModal);
    uiStore.closeAllMobileMenus();
  };

  const handleSearchToggle = () => {
    uiStore.toggleSearch();
  };

  const handleMobileMenuToggle = () => {
    uiStore.toggleMobileMenu();
  };

  const handleLogin = () => {
    sessionStorage.setItem('originalUrl', window.location.href);
    window.location.href = process.env.REACT_APP_ESAB_LOGIN_URL;
  };

  const handleLogout = () => {
    authStore.clear();
    window.location.href = '/';
  };

  const handleSearchClose = () => {
    uiStore.closeAllMobileMenus();
  };

  const renderAuthButton = () => {
    if (authStore.isAuthenticated) {
      return (
        <IconButton onClick={handleLogout}>
          <IconWrapper>
            <LoginIcon />
          </IconWrapper>
          <ButtonText>Logout</ButtonText>
        </IconButton>
      );
    }

    return (
      <IconButton onClick={handleLogin}>
        <IconWrapper>
          <LoginIcon />
        </IconWrapper>
        <ButtonText>Login</ButtonText>
      </IconButton>
    );
  };

  const renderMobileAuthButton = () => {
    if (authStore.isAuthenticated) {
      return (
        <MobileMenuItem onClick={handleLogout}>
          <IconWrapper>
            <LoginIcon />
          </IconWrapper>
          <ButtonText>Logout</ButtonText>
        </MobileMenuItem>
      );
    }

    return (
      <MobileMenuItem onClick={handleLogin}>
        <IconWrapper>
          <LoginIcon />
        </IconWrapper>
        <ButtonText>Login</ButtonText>
      </MobileMenuItem>
    );
  };

  return (
    <>
      <HeaderContainer>
        <Content>
          <HeaderContent>
            {isMobile ? (
              <>
                <MobileLeftSection>
                  <IconButton onClick={handleMobileMenuToggle}>
                    <IconWrapper>
                      <HamburgerIcon />
                    </IconWrapper>
                  </IconButton>
                </MobileLeftSection>
                <MobileCenterSection>
                  {uiStore.showLogo && (
                    <LogoLink href="/">
                      <MobileLogo src={logo} alt="Logo" />
                    </LogoLink>
                  )}
                </MobileCenterSection>
                <MobileRightSection>
                  <IconButton onClick={handleSearchToggle}>
                    <IconWrapper>
                      <SearchIcon />
                    </IconWrapper>
                  </IconButton>
                </MobileRightSection>
              </>
            ) : (
              <>
                <LeftSection>
                  {uiStore.showLogo && (
                    <LogoLink href="/">
                      <Logo src={logo} alt="Logo" />
                    </LogoLink>
                  )}
                  <FormWrapper logo={uiStore.showLogo}>
                    <SearchForm />
                  </FormWrapper>
                  <IconButton onClick={handleInfoModalToggle}>
                    <IconWrapper>
                      <InfoIcon />
                    </IconWrapper>
                    <ButtonText>Search Info</ButtonText>
                  </IconButton>
                </LeftSection>
                <RightSection>
                  <IconButton onClick={handleSelectModalToggle}>
                    <IconWrapper>
                      <GlobeIcon />
                    </IconWrapper>
                    <ButtonText>
                      {region || ''}
                      <DropdownArrowWrapper>
                        {showSelectModal ? (
                          <DropdownArrowOpen />
                        ) : (
                          <DropdownArrowClosed />
                        )}
                      </DropdownArrowWrapper>
                    </ButtonText>
                  </IconButton>
                  {renderAuthButton()}
                </RightSection>
              </>
            )}
          </HeaderContent>
        </Content>
      </HeaderContainer>

      {isMobile && (
        <>
          <SearchOverlayBackground
            isOpen={uiStore.isSearchOpen}
            onClick={handleSearchClose}
          />
          <MobileSearchOverlay isOpen={uiStore.isSearchOpen}>
            <SearchForm />
          </MobileSearchOverlay>

          <MobileMenu isOpen={uiStore.isMobileMenuOpen}>
            <MobileMenuItem
              onClick={() => {
                handleInfoModalToggle();
                uiStore.closeAllMobileMenus();
              }}
            >
              <IconWrapper>
                <InfoIcon />
              </IconWrapper>
              <ButtonText>Search Info</ButtonText>
            </MobileMenuItem>
            <MobileMenuItem
              onClick={() => {
                handleSelectModalToggle();
                uiStore.closeAllMobileMenus();
              }}
            >
              <IconWrapper>
                <GlobeIcon />
              </IconWrapper>
              <ButtonText>
                {region || ''}
                <DropdownArrowWrapper>
                  {showSelectModal ? (
                    <DropdownArrowOpen />
                  ) : (
                    <DropdownArrowClosed />
                  )}
                </DropdownArrowWrapper>
              </ButtonText>
            </MobileMenuItem>
            {renderMobileAuthButton()}
          </MobileMenu>
        </>
      )}

      <InfoModal isVisible={showInfoModal} onClose={handleInfoModalToggle} />
      <SelectModal
        isVisible={showSelectModal}
        onClose={handleSelectModalToggle}
      />
    </>
  );
});

export default Header;
