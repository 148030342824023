import styled from 'styled-components';

export const RegionFilterSeparator = styled.div`
  margin-top: 45px;
  padding-top: 25px;
  border-top: 1px solid ${({ theme: { colors } }) => colors.esabLightGray};

  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
    border: none;
  }
`;

export const FilterGroup = styled.div``;

export const SidebarContainer = styled.div`
  width: 210px;
  margin-top: 40px;
  padding-right: 20px;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 0px;

    ${FilterGroup} {
      display: none;
    }
  }
`;

export const FilterHeaderButtons = styled.div`
  display: flex;
  color: ${({ theme: { colors } }) => colors.esabBlackGray};
  font-weight: 700;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 0 16px;
  }

  span {
    display: flex;
    align-items: center;

    svg {
      margin-right: 8px;
    }
  }
`;

export const FilterHeader = styled.button<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 210px;
  line-height: 30px;
  padding: 8px;
  background: #fff;
  border: 1px solid ${({ theme: { colors } }) => colors.esabLightGray};
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  @media (max-width: 768px) {
    width: 100%;
  }

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.esabLightGray};
  }
`;

export const FilterTitle = styled.span`
  font-size: 16px;
  color: ${({ theme: { colors } }) => colors.esabBlackGray};
`;

export const FilterCount = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.esabCoolGray};
`;
export const RegionTitleWrapper = styled.div`

  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme: { colors } }) => colors.esabBlackGray};
  font-weight: 700;
  margin-bottom: 16px;


  @media (max-width: 768px) {
    display: none;
  }
`;

export const FilterTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ theme: { colors } }) => colors.esabBlackGray};
  font-weight: 700;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
`;

export const ClearFilterButtonMobile = styled.button<{ isActive: boolean }>`
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  padding: 10px;
  margin-left: 10px;
  border-radius: 6px;
  color: ${({ theme: { colors } }) => colors.esabGray};
  background: ${({ theme: { colors }, isActive }) =>
    isActive ? colors.esabWhite : colors.esabLightGray};
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
  transition: background-color 0.3s ease;

  &:hover {
    background: ${({ theme: { colors }, isActive }) =>
      isActive ? colors.esabLightGray : colors.esabLightGray};
  }

  ${({ isActive }) =>
    !isActive &&
    `
    pointer-events: none;
    opacity: 0.7;
  `}
`;

export const ClearFilterButton = styled.button<{ isActive: boolean }>`
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  padding: 10px;
  border-radius: 6px;
  color: ${({ theme: { colors } }) => colors.esabGray};
  background: ${({ theme: { colors }, isActive }) =>
    isActive ? colors.esabWhite : colors.esabLightGray};
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
  transition: background-color 0.3s ease;

  &:hover {
    background: ${({ theme: { colors }, isActive }) =>
      isActive ? colors.esabLightGray : colors.esabLightGray};
  }

  ${({ isActive }) =>
    !isActive &&
    `
    pointer-events: none;
    opacity: 0.7;
  `}

  @media (max-width: 768px) {
    display: none;
  }
`;

export const FilterButton = styled(ClearFilterButton)`
  background: ${({ theme: { colors } }) => colors.esabWhite};
  display: none;
  position: fixed;
  top: 160px;
  z-index: 100;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);

  @media (max-width: 768px) {
    display: block;
    top: 130px;
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;
    border: 1px solid ${({ theme: { colors } }) => colors.esabButtonBorder};

    & span {
      margin-left: 4px;
      margin-top: 1px;
    }
  }
`;

export const MobileFilterOverlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: 0.3s ease-in-out;
  z-index: 998;
`;

export const MobileFilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.esabLightGray};
  margin-bottom: 16px;

  span {
    font-weight: 700;
    font-size: 18px;
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 4px;
`;

export const DrawerContent = styled.div`
  padding-bottom: 32px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;
