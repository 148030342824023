import styled from "styled-components";
import { Button } from "./Input";

export const SuggestionsWrapper = styled.div<{ showSuggestions: boolean }>`
  position: absolute;
  z-index: 1;
  width: 500px;
  background-color: ${({ theme }) => theme.colors.esabWhite};
  border: ${(props) =>
    props.showSuggestions
      ? `1px solid ${props.theme.colors.esabLightGray}`
      : 'none'};
  border-top: none;
  border-radius: 6px;
  box-shadow: ${(props) =>
    props.showSuggestions ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none'};
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    border-radius: 0;
  }
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const SearchInputWrapper = styled.div`
  margin-top: 10px;
  width: 80%;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const SearchButton = styled(Button)`
  align-items: center;
  height: 55px;
  width: 100px;
  border: 1px solid ${({ theme }) => theme.colors.esabGray};

  @media (max-width: 768px) {
    display: none;
  }
`;