import { autorun, makeAutoObservable, runInAction } from 'mobx';
import { deserialize, serialize } from 'serializr';
import { isEqual } from 'lodash';
import Form from '../models/Form';
import {
  getUrlParameter,
  defaultLanguage,
  regions,
  languages,
  types,
  defaultOrderBy,
  itemsPerPage,
} from '../settings';
import PdfStore from './PdfStore';
import { searchApi } from '../services/api';

export default class SearchStore {
  pdfStore: PdfStore;
  user = '';
  form: Form;
  hasPerformedSearch = false;
  searching = false;
  lastSearchedTerm = '';
  facets: any = {};

  constructor(pdfStore: PdfStore) {
    this.pdfStore = pdfStore;
    makeAutoObservable(this);
    this.user = getUrlParameter('user') || 'public';
    this.form = deserialize(Form, this.defaultValues);

    const params = new URLSearchParams(window.location.search);
    const searchText = params.get('text');
    const type = params.get('type');
    const orderBy = params.get('orderby');

    if (searchText || type || orderBy) {
      if (searchText) this.form.setText(searchText);
      if (type) this.form.setType(type.split(','));
      if (orderBy) this.form.setOrderby(orderBy);

      this.performSearch().then(() => {
        setTimeout(() => {
          const scrollPosition = sessionStorage.getItem('scrollPosition');
          if (scrollPosition) {
            window.scrollTo(0, parseInt(scrollPosition));
            sessionStorage.removeItem('scrollPosition');
          }
        }, 100);
      });
    }

    autorun(() => {
      const invalidLanguage = !this.availableLanguages
        .map((language) => language.key)
        .includes(this.form.language);

      if (invalidLanguage) {
        runInAction(() => {
          this.form.setLanguage('');
        });
      }
    });
  }

  // removes spaces when searching for doc no
  cleanNumbersOnly(str: string): string {
    if (/^[\d\s]+$/.test(str)) {
      return str.replace(/\s+/g, '');
    }
    return str;
  }

  clearForm = () => {
    this.form = deserialize(Form, this.defaultValues);
  };

  get defaultValues() {
    const typeParam = getUrlParameter('type');
    const categoryParam = getUrlParameter('category');
    return {
      text: getUrlParameter('text'),
      type: typeParam ? typeParam.split(',') : [],
      category: categoryParam ? categoryParam.split(',') : [],
      subcategory: getUrlParameter('subcategory'),
      region: getUrlParameter('region'),
      language: getUrlParameter('language') || defaultLanguage,
      orderBy: getUrlParameter('orderby') || defaultOrderBy,
      strictRegionChecking: false,
    };
  }

  get filter(): string {
    return this.getFilter();
  }

  get formIsDirty() {
    return !isEqual(serialize(this.form), this.defaultValues);
  }

  get OrderBy(): string {
    const { orderBy } = this.form.trimmedValues;
    switch (orderBy) {
      case 'Date':
        return 'releasedate desc';
      case 'Manualtitle':
        return 'manualtitle asc';
      default:
        return '';
    }
  }
  performSearch = async (page = 1) => {
    let trimmedText = this.form.trimmedValues.text;
    trimmedText = this.cleanNumbersOnly(trimmedText);

    const searchterm = trimmedText || '*';
    this.lastSearchedTerm = trimmedText;
    this.hasPerformedSearch = true;
    this.setSearching(true);

    const url = new URL(window.location.href);

    this.setQueryParameter(url, 'text', trimmedText);
    this.setQueryParameter(url, 'type', this.form.trimmedValues.type.join(','));
    this.setQueryParameter(url, 'category', this.form.trimmedValues.category);
    this.setQueryParameter(
      url,
      'subcategory',
      this.form.trimmedValues.subcategory
    );
    this.setQueryParameter(url, 'orderby', this.form.trimmedValues.orderBy);
    this.setQueryParameter(url, 'type', this.form.trimmedValues.type.join(','));
    this.setQueryParameter(
      url,
      'category',
      this.form.trimmedValues.category.join(',')
    );

    window.history.pushState(null, document.title, url.href);

    try {
      const response = await searchApi.post(
        `${process.env.REACT_APP_AZURE_SEARCH_URL}indexes/${process.env.REACT_APP_AZURE_SEARCH_INDEX}/docs/search?api-version=${process.env.REACT_APP_AZURE_SEARCH_API_VERSION}`,
        {
          count: true,
          skip: (page - 1) * itemsPerPage,
          top: itemsPerPage,
          searchMode: 'any',
          queryType: 'simple',
          facets: [
            'subcategory,count:5,sort:count',
            'manualtype,count:5,sort:count',
            'productcategory,count:5,sort:count',
          ],
          search: searchterm,
          filter: this.getFilter(),
          orderby: this.OrderBy,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'api-key': process.env.REACT_APP_AZURE_API_KEY,
          },
        }
      );

      runInAction(() => {
        this.pdfStore.setItems(response.data.value);
        this.pdfStore.setPage(page);
        this.pdfStore.setTotalCount(response.data['@odata.count']);
        this.facets = response.data['@search.facets'];
      });
    } catch (error) {
      console.error('Search error:', error);
      runInAction(() => {
        this.pdfStore.setItems([]);
        this.pdfStore.setPage(1);
        this.pdfStore.setTotalCount(0);
      });
    } finally {
      this.setSearching(false);
    }
  };

  getFilter(): string {
    const filter = [];

    const { type, category, subcategory } = this.form.trimmedValues;

    if (type.length > 0) {
      filter.push(
        `manualtype/any(t: ${type.map((t) => `t eq '${t}'`).join(' or ')})`
      );
    }

    if (category.length > 0) {
      filter.push(
        `(${category.map((c) => `productcategory eq '${c}'`).join(' or ')})`
      );
    }

    if (subcategory.length) {
      filter.push(`subcategory eq '${subcategory}'`);
    }

    if (this.pdfStore.selectedMarket) {
      filter.push(`market/${this.pdfStore.selectedMarket} eq true`);
    }

    return filter.join(' and ');
  }

  setQueryParameter = (url: URL, key: string, value: string | string[]) => {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        url.searchParams.set(key, value.join(','));
      } else {
        url.searchParams.delete(key);
      }
    } else if (value) {
      url.searchParams.set(key, value);
    } else {
      url.searchParams.delete(key);
    }
  };

  setSearching = (searching: boolean) => {
    this.searching = searching;
  };

  get availableLanguages(): typeof languages {
    const region = regions.find((region) => region.key === this.form.region);
    if (!region) {
      return languages;
    }
    return languages.filter(
      (language, index) =>
        index === 0 || region.languages.includes(language.key)
    );
  }

  get availableTypes(): typeof types {
    return types.filter((type) => !type.access || type.access === this.user);
  }

  get shouldPerformInitialSearch(): boolean {
    const { text, type, region, category, language } = this.form.trimmedValues;
    return (
      !this.hasPerformedSearch &&
      (text.length > 0 ||
        type.length > 0 ||
        category.length > 0 ||
        region.length > 0 ||
        language.length > 0)
    );
  }
}
