import styled from "styled-components";

export const SelectWrapper = styled.div`
  margin-bottom: 0px;
`;

export const SelectLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;
`;

export const OptionsList = styled.div<{ itemCount: number }>`
  background-color: ${({ theme }) => theme.colors.esabWhite};
  border: 1px solid ${({ theme }) => theme.colors.esabLightGray};
  border-radius: 4px;

  max-height: ${({ itemCount }) => (itemCount > 5 ? '220px' : 'none')};
  overflow-y: ${({ itemCount }) => (itemCount > 5 ? 'auto' : 'visible')};
`;

export const OptionItem = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.esabLightGray};
  }
`;

export const CheckboxWrapper = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
`;

export const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const CustomCheckbox = styled.span<{ isChecked: boolean }>`
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid ${({ theme }) => theme.colors.esabLightBlueGray};
  background: #fff;

  &::after {
    content: '';
    position: absolute;
    display: ${(props) => (props.isChecked ? 'block' : 'none')};
    left: 5px;
    top: 2px;
    width: 3px;
    height: 8px;
    border: solid ${({ theme }) => theme.colors.esabLightBlueGray};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

export const OptionLabel = styled.label`
  font-size: 14px;
  cursor: pointer;
  user-select: none;
`;

export const OptGroup = styled.div`
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.esabButtonBorder};
`;

export const OptGroupLabel = styled.div`
  font-weight: bold;
  padding: 8px;
  background-color: #f0f0f0;
`;