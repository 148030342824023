import React from 'react';
import { isOptionGroup, SelectItem } from '../../settings';
import {
  Checkbox,
  CheckboxWrapper,
  CustomCheckbox,
  OptGroup,
  OptGroupLabel,
  OptionItem,
  OptionLabel,
  OptionsList,
  SelectLabel,
  SelectWrapper,
} from '../styled/InputSelect';

type Props = {
  title?: string;
  name: string;
  values: SelectItem[];
  value: string[];
  onChange: (selectedValues: string[]) => void;
  stripValueRegex?: RegExp | string;
  children?: React.ReactNode;
  className?: string;
};

const InputSelect: React.FC<Props> = ({
  title,
  name,
  values,
  value,
  onChange,
  stripValueRegex,
  children,
  className,
}) => {
  const handleOptionClick = (itemKey: string) => {
    const newValue = value.includes(itemKey)
      ? value.filter((v) => v !== itemKey)
      : [...value, itemKey];
    onChange(newValue);
  };

  const renderOption = (item: { key: string; value: string }) => (
    <OptionItem
      key={item.key}
      onClick={() => handleOptionClick(item.key)}
      isSelected={value.includes(item.key)}
    >
      <CheckboxWrapper>
        <Checkbox
          type="checkbox"
          checked={value.includes(item.key)}
          onChange={() => handleOptionClick(item.key)}
          onClick={(e) => {
            e.stopPropagation();
            handleOptionClick(item.key);
          }}
        />
        <CustomCheckbox isChecked={value.includes(item.key)} />
      </CheckboxWrapper>
      <OptionLabel>
        {stripValueRegex ? item.value.replace(stripValueRegex, '') : item.value}
      </OptionLabel>
    </OptionItem>
  );

  return (
    <SelectWrapper className={className}>
      {title && <SelectLabel>{title}</SelectLabel>}
      <OptionsList itemCount={values.length}>
        {values.map((item) =>
          isOptionGroup(item) ? (
            <OptGroup key={item.title}>
              <OptGroupLabel>{item.title}</OptGroupLabel>
              {item.items?.map(renderOption)}
            </OptGroup>
          ) : (
            renderOption(item)
          )
        )}
      </OptionsList>
      {children}
    </SelectWrapper>
  );
};

export default InputSelect;
