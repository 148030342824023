import { useLocation } from 'react-router-dom';
import {
  formatManualUrl,
  isHtmlManual,
  ManualVariant,
} from '../components/helpers/urlUtils';
import { useConfig } from './useAuth';

import { BASE_PATH } from '../components/helpers/urlUtils';

export const useManualUrl = () => {
  const location = useLocation();

  const getManualUrl = (variant: ManualVariant) => {
    if (isHtmlManual(variant.manualtype)) {
      const urlParts = variant.url.split('/');
      const manualId = urlParts[urlParts.length - 1];
      return `${BASE_PATH}/${manualId}`;
    }
    return variant.url;
  };

  const getCurrentManualId = () => {
    const matches = location.pathname.match(
      /\/manuals\/(\d+_[a-z]{2}-[A-Z]{2})/
    );
    return matches ? matches[1] : null;
  };

  return {
    getManualUrl,
    getCurrentManualId,
  };
};
