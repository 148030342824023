import styled from "styled-components";

export const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
`;

export const ModalWindow = styled.div`
  max-width: 810px;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.esabWhite};
  box-shadow: 1px 2px 8px 2px rgba(0, 0, 0, 0.25);
  border-radius: 12px;

  @media (max-width: 768px) {
    height: 50%;
    overflow-y: auto;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const CloseIcon = styled.img`
  cursor: pointer;
`;

export const ModalTitle = styled.h2`
  font-size: 26px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.esabBlack};
  margin: 0;
`;