import * as React from 'react';
import { useFetchTopic } from '../../hooks/useFetchData';
import Loader from './Loader';
import StringToComponent from './StringToComponent';

type TopicContent = {
  topicId: string;
};

const TopicContent: React.FC<TopicContent> = ({ topicId }) => {
  const { isLoading, isError, data } = useFetchTopic(topicId);

  if (!data || isError) {
    return null;
  }

  return <>{isLoading ? <Loader /> : <StringToComponent data={data} />}</>;
};

export default TopicContent;
