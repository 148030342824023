import styled from 'styled-components';
import { ReactComponent as DropdownArrowClosed } from '../../assets/images/dropdownArrowClosed.svg';
import { ReactComponent as DropdownArrowOpen } from '../../assets/images/dropdownArrowOpen.svg';

export const LanguageDropdownWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const DropdownButton = styled.div`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.esabLightGray};
  background: ${({ theme }) => theme.colors.esabWhite};
  padding: 9px 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.esabBlackGray};
  cursor: pointer;
  position: relative;

  .language {
    font-weight: 700;
  }

  .date {
    color: ${({ theme }) => theme.colors.esabCoolGray};
  }

  .short-type {
    color: ${({ theme }) => theme.colors.esabCoolGray};
    margin-left: 4px;
    margin-right: 4px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.esabLightGray};
  }
`;

export const DropdownList = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid ${({ theme }) => theme.colors.esabLightGray};
  border-top: none;
  background: ${({ theme }) => theme.colors.esabWhite};
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 6px 0 0 6px;
  margin-top: 4px;
`;

export const DropdownItem = styled.div`
  padding: 9px 8px;
  cursor: pointer;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.esabBlackGray};
  border-bottom: 1px solid ${({ theme }) => theme.colors.esabLightGray};
  border-top: 1px solid ${({ theme }) => theme.colors.esabLightGray};

  .dropdown-content {
    display: flex;
    flex-direction: column;
  }

  .main-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .language {
    font-weight: 700;
  }

  .date {
    color: ${({ theme }) => theme.colors.esabCoolGray};
  }

  .full-type {
    color: ${({ theme }) => theme.colors.esabCoolGray};
    margin-top: 12px;
    font-size: 12px;
    font-style: italic;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.esabLightGray};
  }
`;

export const DropdownArrowWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;

export const StyledDropdownArrowClosed = styled(DropdownArrowClosed)`
  width: 12px;
  height: 12px;
  fill: ${({ theme }) => theme.colors.esabBlackGray};
`;

export const StyledDropdownArrowOpen = styled(DropdownArrowOpen)`
  width: 12px;
  height: 12px;
  fill: ${({ theme }) => theme.colors.esabBlackGray};
`;

export const Description = styled.div`
  font-style: italic;
  font-weight: 100;
  font-size: 18px;
  margin-bottom: 10px;
`;

export const Type = styled.div`
  font-size: 16px;
  color: #888;
  @media screen and (max-width: 600px) {
    margin-bottom: 8px;
  }
`;

export const Details = styled.div`
  border-top: 1px solid #eee;
  color: #333;
  padding: 5px 20px;
  font-size: 11px;
  .bold {
    font-weight: bold;
  }
`;

export const Container = styled.div`
  margin-bottom: 10px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  &:hover {
    Button {
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.75);
    }
  }
`;

export const ColumnWrapper = styled.div`
  padding: 20px;
  &:after {
    content: '';
    display: table;
    clear: both;
  }
`;

export const Column = styled.div<{ size?: string }>`
  float: left;
  width: ${(props) => (props.size === 'large' ? '40%' : '30%')};
  box-sizing: border-box;
  padding-right: 20px;
  &:last-child {
    padding-right: 0;
    text-align: right;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const Item = styled.div`
  font-size: 16px;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const ItemTitle = styled.h4`
  margin: 0 0 2px 0;
  font-size: 12px;
  text-transform: uppercase;
`;

export const Link = styled.a`
  height: 38px;
  font-size: 16px;
  background-color: #FFF;
  color: #333;
  border: 0;
  padding: 10px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;

  @media screen and (max-width: 768px) {
    float: left;
    height: 20px;
  }

  &:hover {
    background-color: #E8E8E8;
  }
  &:active {
    background-color: #9A999B;
    color: #E8E8E8;
    svg path,
    svg rect,
    svg line,
    svg ellipse,
    svg circle {
      &:not[fill='none'] {
        fill: #E8E8E8;
      }
      stroke: #E8E8E8;
    }

    
`;

export const ButtonIcon = styled.span`
  vertical-align: middle;
  margin-right: 10px;
`;
