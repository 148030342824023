import * as React from 'react';
import Page from '../layout/Page';
import PageContentWrapper from '../layout/PageContentWrapper';
import styled from 'styled-components';
import H1 from '../typography/H1';
import H2 from '../typography/H2';
import { useFetchStartPage } from '../../../hooks/useFetchData';
import SearchBox from '../search/SearchBox';
import Loader from '../Loader';
import { useConfig } from 'src/hooks/useAuth';
import { Redirect } from 'react-router-dom';
import { useInternalLink } from '../../../hooks/useInternalLink';
import ReactGA from 'react-ga';

const InnerWrapper = styled.div`
  width: 100%;
  max-width: 828px;
  display: flex;
  flex-direction: column;
  align-self: center;
`;

const Hero = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 40px;

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    height: 30vh;
    padding: 0 26px;
  }
`;

const HeroContent = styled.div`
  width: 510px;
`;

const StyledH1 = styled(H1)`
  font-size: ${({ theme }) => theme.fontSizes.xxxl};
  line-height: 57px;
  color: #ffffff;
  text-shadow: 0px 4px 4px #000000;
  text-align: center;
  margin-bottom: 35px;
  text-transform: capitalize;

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    display: none;
  }
`;

const StyledH2 = styled(H2)`
  margin-bottom: 15px;

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    display: none;
  }
`;

const Content = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-self: center;
  max-width: 1080px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const DescriptionWrapper = styled(Content)`
  padding-left: 43px;
  padding-right: 43px;
  justify-content: flex-start;

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    padding-left: 26px;
    padding-right: 26px;
  }

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const LinksWrapper = styled(Content)`
  margin-left: -43px;
  margin-right: -43px;

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -26px;
    margin-right: -26px;
  }

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

const ProductImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26.87%;
  margin-right: 43px;

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    justify-content: flex-start;
  }
`;

const Description = styled.div`
  width: 62%;

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    width: 73.13%;
  }
`;

const DescriptionText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.l};
  line-height: 32px;

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    font-size: ${({ theme }) => theme.fontSizes.s};
    line-height: 16.1px;
  }
`;

const LinkWrapper = styled.div`
  width: calc(100% / 3);
  padding-left: 43px;
  padding-right: 43px;

  @media (max-width: ${({ theme }) => theme.screens.md}) {
    width: calc(100% / 2);
    padding-left: 26px;
    padding-right: 26px;
    margin-bottom: 23px;
  }

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    &:nth-child(even) {
      padding-right: 0;
    }

    &:nth-child(odd) {
      padding-left: 0;
    }
  }
`;

const ProductImage = styled.img`
  max-width: 105px;

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    max-width: 55px;
  }
`;

const Link = styled.a`
  display: flex;
  flex-direction: column;
`;

const LinkImage = styled.img`
  border-radius: ${({ theme }) => theme.radii.l};
`;

const LinkText = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.l};
  line-height: 23px;
  text-transform: uppercase;
  padding-top: 9px;

  @media (max-width: ${({ theme }) => theme.screens.sm}) {
    font-size: ${({ theme }) => theme.fontSizes.xxs};
  }
`;

const Home = () => {
  const { internalLink } = useInternalLink();
  const { isLoading, isError, data } = useFetchStartPage();
  const config = useConfig();

  const getLinkUrl = React.useCallback(
    (target: string): string => {
      if (target.startsWith('https://') || target.startsWith('http://')) {
        return target;
      }

      if (target.match(/^\d+_[a-z]{2}-[A-Z]{2}\/instruction\//)) {
        return internalLink(target);
      }

      return internalLink(`/instruction/${target}`);
    },
    [internalLink]
  );

  if (data?.status === 404) {
    return <Redirect to="/404" />;
  }

  const superscriptRegistermark = (text: string): any => {
    const flatMap = (array: any, fn: any) => {
      let result = [];
      for (let i = 0; i < array.length; i++) {
        const mapping = fn(array[i]);
        result = result.concat(mapping);
      }
      return result;
    };
    if (text === undefined) return '';
    const result = flatMap(text.split('®'), function (part: any) {
      return [part, <sup key="">®</sup>];
    });
    result.pop();
    return result;
  };

  //GOOGLE ANALYTICS STUFF ---------------------------------------------
  if (sessionStorage.getItem('ga-path') != window.location.pathname) {
    sessionStorage.setItem('ga-path', window.location.pathname);
    ReactGA.ga('set', 'title', 'Home');
    ReactGA.ga('set', 'page', window.location.pathname);
    ReactGA.ga('send', 'pageview');
  }
  //--------------------------------------------------------------------

  return (
    <Page hideSearch={true}>
      {isLoading || data === undefined ? (
        <Loader styles={{ alignSelf: 'center', marginTop: 150 }} />
      ) : (
        !isError &&
        config && (
          <>
            <Hero
              style={{
                backgroundImage: `url(${process.env.REACT_APP_ESAB_STORAGE_URL}/images/${data.heroImageId}.jpg)`,
              }}
            >
              <HeroContent>
                <StyledH1>{superscriptRegistermark(data.title)}</StyledH1>
                <SearchBox />
              </HeroContent>
            </Hero>
            <PageContentWrapper>
              <InnerWrapper>
                <DescriptionWrapper>
                  <ProductImageWrapper>
                    <ProductImage
                      src={`${process.env.REACT_APP_ESAB_STORAGE_URL}/images/${data.productImageId}.jpg`}
                    />
                  </ProductImageWrapper>
                  <Description>
                    <StyledH2>{superscriptRegistermark(data.title)}</StyledH2>
                    <DescriptionText>
                      {superscriptRegistermark(data.description)}
                    </DescriptionText>
                  </Description>
                </DescriptionWrapper>
                {data.featuredArticles !== undefined &&
                  data.featuredArticles.length > 0 && (
                    <LinksWrapper>
                      {data.featuredArticles.map((link, index) => (
                        <LinkWrapper key={index}>
                          <Link href={getLinkUrl(link.target)}>
                            <LinkImage
                              src={`${process.env.REACT_APP_ESAB_STORAGE_URL}/images/${link.imageId}.jpg`}
                            />
                            <LinkText>{link.title}</LinkText>
                          </Link>
                        </LinkWrapper>
                      ))}
                    </LinksWrapper>
                  )}
              </InnerWrapper>
            </PageContentWrapper>
          </>
        )
      )}
    </Page>
  );
};

export default Home;
