import React, { useState } from 'react';
import styled from 'styled-components';

interface TruncatedTitleProps {
  title: string;
  maxLength?: number;
  className?: string;
}

const TruncatedTitle = ({
  title,
  maxLength = 80,
  className,
}: TruncatedTitleProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const shouldTruncate = title.length > maxLength;
  const displayText = isExpanded ? title : title.slice(0, maxLength);

  return (
    <TitleWrapper className={className}>
      <TitleText>
        <span dangerouslySetInnerHTML={{ __html: displayText }} />
        {shouldTruncate && (
          <ExpandButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? '' : '...'}
          </ExpandButton>
        )}
      </TitleText>
    </TitleWrapper>
  );
};

interface ArticleTruncatedTitleProps extends TruncatedTitleProps {
  className?: string;
}

export const ArticleTruncatedTitle = ({
  title,
  maxLength = 30,
  className,
}: ArticleTruncatedTitleProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const shouldTruncate = title.length > maxLength;
  const displayText = isExpanded ? title : title.slice(0, maxLength);

  return (
    <StyledArticleWrapper
      className={className}
      onClick={() => shouldTruncate && setIsExpanded(!isExpanded)}
    >
      <span>
        {displayText}
        {shouldTruncate && !isExpanded && <span>...</span>}
      </span>
    </StyledArticleWrapper>
  );
};

const StyledArticleWrapper = styled.div`
  font-size: 11px;
  display: inline-block;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const TitleWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const TitleText = styled.h4`
  font-size: 24px;
  margin: 0 0 2px 0;
`;

const ExpandButton = styled.button.attrs({ className: 'truncate-button' })`
  cursor: pointer;
  background: ${({ theme }) => theme.colors.esabWhite};
  border: none;
  font-size: 28px;
  box-shadow: none !important; /* Override parent hover effect */
  padding: 0 4px;

  &:hover {
    box-shadow: none !important;
  }

  &.truncate-button {
    box-shadow: none !important;
  }
`;

export default TruncatedTitle;
