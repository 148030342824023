import React, { useEffect, useRef } from 'react';
import {
  SuggestionsBox, 
  SuggestionsListItem, 
  SuggestionTitle, 
  SuggestionType
 } from '../styled/Suggestions';

interface Suggestion {
  text: string;
  manualtitle: string;
  productcategory: string;
  manualtype: string;
}

interface SuggestionsProps {
  suggestions: Suggestion[];
  onSuggestionClick: (suggestion: Suggestion) => void;
  visible: boolean;
  onKeyNavigation: (index: number) => void;
  selectedIndex: number;
}

const Suggestions: React.FC<SuggestionsProps> = ({
  suggestions,
  onSuggestionClick,
  visible,
  onKeyNavigation,
  selectedIndex,
}) => {


  const suggestionsBoxRef = useRef<HTMLUListElement>(null);
  
  useEffect(() => {
    if (selectedIndex >= 0 && suggestionsBoxRef.current) {
      const suggestionsBox = suggestionsBoxRef.current;
      const selectedItem = suggestionsBox.children[selectedIndex] as HTMLElement;

      if (selectedItem) {
        const itemTop = selectedItem.offsetTop;
        const itemBottom = itemTop + selectedItem.clientHeight;
        const scrollTop = suggestionsBox.scrollTop;
        const scrollBottom = scrollTop + suggestionsBox.clientHeight;

        if (itemBottom > scrollBottom) {
          suggestionsBox.scrollTop = itemBottom - suggestionsBox.clientHeight;
        } else if (itemTop < scrollTop) {
          suggestionsBox.scrollTop = itemTop;
        }
      }
    }
  }, [selectedIndex]);
  
  if (!visible || suggestions.length === 0) {
    return null;
  }
  return (
    <SuggestionsBox ref={suggestionsBoxRef}>
      {suggestions.map((suggestion, index) => (
        <SuggestionsListItem
          key={index}
          onClick={() => onSuggestionClick(suggestion)}
          isSelected={index === selectedIndex}
          onMouseEnter={() => onKeyNavigation(index)}
        >
          <SuggestionTitle
            dangerouslySetInnerHTML={{ __html: suggestion.manualtitle }}
          />
          <SuggestionType>{suggestion.manualtype}</SuggestionType>
        </SuggestionsListItem>
      ))}
    </SuggestionsBox>
  );
};

export default Suggestions;
